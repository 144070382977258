<template>
  <MealPlanWidget
    v-if="isReady"
    class="u-hidden-print"
    :model="model"
  />
</template>
<script>

export default {
  components: {
    MealPlanWidget: () => import('@/CVI/Purchase/components/CustomMealPlan/Widget.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  created() {
    this.isReady = true
  }
}
</script>
