(function ($, _) {
  window.vt = window.vt || {}

  // Constants
  const CLASS_NAME = 'generic-carousel'
  const ACTIVE_CLASS = 'is-active'
  const TRANSITION_CLASS = 'in-transition'

  const SLIDE_WIDTH = 0.7
  const SMALL_BREAKPOINT = 640
  const MEDIUM_BREAKPOINT = 960
  const LARGE_BREAKPOINT = 1024

  vt.GenericCarousel = (function () {
    function create(element) {
      let carouselContainerNode
      let carouselPrevButtonNode
      let carouselNextButtonNode

      let children = Array.prototype.slice.call(element.children)
      const options = _.pick(element.dataset, ['nextLabel', 'prevLabel'])

      let currentSlideConfig
      let currentDragPos = {}
      let startDragPos = {}
      let inTransition = false

      // Composed Functions
      const shiftSlidePrevHandler = _.partial(shiftButtonHandler, -1)
      const shiftSlideNextHandler = _.partial(shiftButtonHandler, 1)

      const mqlArr = [
        addMql(`(min-width: 0px) and (max-width: ${SMALL_BREAKPOINT - 1}px)`, 1),
        addMql(`(min-width: ${SMALL_BREAKPOINT}px) and (max-width: ${MEDIUM_BREAKPOINT - 1}px)`, 2),
        addMql(`(min-width: ${MEDIUM_BREAKPOINT}px)`, 3)
      ]

      // Init
      init()

      function init() {
        // NOTE: Shift last slide to first.
        children.unshift(children.pop())

        // NOTE: At least 5 slides is needed to create the loop, if there are less they must be cloned.
        while (children.length < 5) {
          children = children.concat(_.map(children, childNode => childNode.cloneNode(true)))
        }

        // NOTE: Update DOM
        carouselContainerNode = document.createElement('div')
        carouselContainerNode.classList.add(`${CLASS_NAME}__container`)
        carouselContainerNode.setAttribute('tabindex', 0)

        // NOTE: Append slides.
        appendSlides(carouselContainerNode)

        // NOTE: Append controls.
        appendControls(element)

        // NOTE: Set MQL listeners.
        _.forEach(mqlArr, addMqListener)

        // NOTE: Add keyboard listeners.
        carouselContainerNode.addEventListener('keydown', keyboardNavigationHandler)
        carouselContainerNode.addEventListener('touchstart', startDragHandler)
        carouselContainerNode.addEventListener('touchmove', dragHandler)
        carouselContainerNode.addEventListener('touchend', endDragHandler)

        // NOTE: Ready...
        element.classList.add(ACTIVE_CLASS)
      }

      function appendSlides(containerNode) {
        _.forEach(_.map(children, createItemNode), containerNode.appendChild.bind(containerNode))

        element.appendChild(carouselContainerNode)
      }

      function appendControls(containerNode) {
        carouselPrevButtonNode = createButtonNode(options.prevLabel, 'prev')
        carouselNextButtonNode = createButtonNode(options.nextLabel, 'next')

        carouselPrevButtonNode.addEventListener('click', shiftSlidePrevHandler)
        carouselNextButtonNode.addEventListener('click', shiftSlideNextHandler)

        containerNode.appendChild(carouselPrevButtonNode)
        containerNode.appendChild(carouselNextButtonNode)
      }

      function createItemNode(childNode) {
        const itemNode = document.createElement('div')
        itemNode.classList.add(`${CLASS_NAME}__item`)

        itemNode.appendChild(childNode)

        return itemNode
      }

      function createButtonNode(label, modifier) {
        const buttonNode = document.createElement('button')
        buttonNode.classList.add(`${CLASS_NAME}__button`)
        buttonNode.classList.add(`${CLASS_NAME}__button--${modifier}`)
        buttonNode.innerHTML = `<span class="${CLASS_NAME}__button-label">${label}</span>`
        buttonNode.setAttribute('type', 'button')
        buttonNode.setAttribute('aria-controls', element.id)

        return buttonNode
      }

      function addMql(mq, slideCount) {
        const mql = window.matchMedia(mq)

        mql.slideConfig = {
          count: slideCount
        }

        return mql
      }

      function addMqListener(mql) {
        // NOTE: Check initial match.
        resizeHandler(mql)

        // NOTE: Add listener.
        mql.addListener(resizeHandler)
      }

      function removeMqListener(mql) {
        // NOTE: Remove listener.
        mql.removeListener(resizeHandler)
      }

      function keyboardNavigationHandler(evt) {
        if (evt.keyCode === 37) {
          shiftSlide(-1)
        } else if (evt.keyCode === 39) {
          shiftSlide(1)
        }
      }

      function resizeHandler(evt) {
        if (evt.matches) {
          currentSlideConfig = _.get(evt, 'slideConfig') || _.get(evt, 'currentTarget.slideConfig')
          const slideCount = currentSlideConfig.count

          const containerWidth = ((children.length * SLIDE_WIDTH) / slideCount) * 100
          const slideWidth = 1 / children.length
          const xPos = slideWidth - getSlideOffset(slideCount)

          carouselContainerNode.style.width = `${containerWidth}%`
          carouselContainerNode.style.transform = `translate(${xPos * -100}%, 0)`
        }
      }

      function startDragHandler(evt) {
        startDragPos = {
          x: evt.touches[0].screenX,
          y: evt.touches[0].screenY
        }

        currentDragPos = {
          x: evt.touches[0].screenX,
          y: evt.touches[0].screenY
        }
      }

      function dragHandler(evt) {
        currentDragPos = {
          x: evt.touches[0].screenX,
          y: evt.touches[0].screenY
        }
      }

      function endDragHandler(evt) {
        if (currentDragPos.x !== startDragPos.x) {
          shiftSlide(currentDragPos.x < startDragPos.x ? 1 : -1)
        }

        startDragPos = {}
        currentDragPos = {}
      }

      function shiftButtonHandler(shiftCount, evt) {
        evt.preventDefault()

        shiftSlide(shiftCount)
      }

      function shiftSlide(shiftCount) {
        const slideWidth = 1 / children.length
        const shift = slideWidth * shiftCount

        if (shiftCount > 0) {
          var xPos = slideWidth - getSlideOffset(currentSlideConfig.count)

          transition((xPos + shift) * -100, {
            after() {
              carouselContainerNode.appendChild(carouselContainerNode.firstChild)
              carouselContainerNode.style.transform = `translate(${xPos * -100}%, 0)`
            }
          })
        } else {
          var xPos = (slideWidth * 2) - getSlideOffset(currentSlideConfig.count)

          transition((xPos + shift) * -100, {
            before() {
              carouselContainerNode.insertBefore(carouselContainerNode.lastChild, carouselContainerNode.firstChild)
              carouselContainerNode.style.transform = `translate(${xPos * -100}%, 0)`
            }
          })
        }
      }

      function getSlideOffset(slideCount) {
        const scaleFactor = children.length * SLIDE_WIDTH

        return (((1 - SLIDE_WIDTH) / 2) / scaleFactor) * slideCount
      }

      function transition(xPos, callbacks) {
        if (inTransition) {
          return
        }

        inTransition = true

        // TODO: Get correct event name for IE.
        var transitionEndHandler = function () {
          requestAnimationFrame(() => {
            inTransition = false

            _.get(callbacks, 'after', _.noop)()

            carouselContainerNode.classList.remove(TRANSITION_CLASS)
            carouselContainerNode.removeEventListener('transitionend', transitionEndHandler)
          })
        }

        requestAnimationFrame(() => {
          _.get(callbacks, 'before', _.noop)()

          requestAnimationFrame(() => {
            carouselContainerNode.addEventListener('transitionend', transitionEndHandler)

            carouselContainerNode.classList.add(TRANSITION_CLASS)
            carouselContainerNode.style.transform = `translate(${xPos}%, 0)`
          })
        })
      }

      function destroy() {
        // NOTE: Remove carouselContainerNode listeners.
        carouselContainerNode.removeEventListener('keydown', keyboardNavigationHandler)
        carouselContainerNode.removeEventListener('touchstart', startDragHandler)
        carouselContainerNode.removeEventListener('touchmove', dragHandler)
        carouselContainerNode.removeEventListener('touchend', endDragHandler)

        // NOTE: Remove controlNode listeners.
        carouselPrevButtonNode.removeEventListener('click', shiftSlidePrevHandler)
        carouselNextButtonNode.removeEventListener('click', shiftSlideNextHandler)

        // NOTE: Remove MQ listeners.
        _.forEach(mqlArr, removeMqListener)
      }

      return {
        destroy
      }
    }

    return {
      initialize: create
    }
  }())
}(jQuery, _))
