(function (_) {
  window.vt = window.vt || {}

  function parseQuery(query) {
    if (!query) {
      return {}
    }

    return (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        const paramArr = param.split('=')

        const key = paramArr[0]
        const value = paramArr[1]

        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''

        return params
      }, {})
  }

  function uniqBy(arr, fn) {
    if (!_.isArray(arr)) {
      throw new TypeError('"arr" parameter must be an array')
    }

    if (!_.isFunction(fn)) {
      throw new TypeError('"fn" parameter must be a function')
    }

    return arr.reduce((baseArr, item) => (_.includes(baseArr.map(fn), fn(item)) ? baseArr : baseArr.concat(item)), [])
  }

  function addAntiForgeryToken(data) {
    const verificationTokenInput = document.querySelector('input[name=__RequestVerificationToken]')
    if (verificationTokenInput) {
      data['X-XSRF-Token'] = verificationTokenInput.value
    }

    return data
  }

  function getAntiForgeryToken() {
    let token = ''
    const verificationTokenInput = document.querySelector('input[name=__RequestVerificationToken]')
    if (verificationTokenInput) {
      token = verificationTokenInput.value
    }

    return token
  }

  vt.Utils = {
    parseQuery,
    uniqBy,
    addAntiForgeryToken,
    getAntiForgeryToken
  }
}(_))
