(function (_) {
  window.vt = window.vt || {}

  // TODO: Replace duplicate usage in Purchase/shopping-list.js
  vt.UrlHelpers = {
    getQueryStringValues,
    getQueryString
  }

  // Functions
  function getQueryString(data) {
    return Object.keys(data).reduce((queryStr, key, i) => {
      const value = data[key]
      const valueStr = value && value.toString()
      const prefix = (i > 0 && queryStr.length > 1) ? '&' : ''
      const keyValuePairStr = valueStr && (`${prefix + key}=${valueStr}`)

      return keyValuePairStr ? queryStr.concat(keyValuePairStr) : queryStr
    }, '?')
  }

  function getQueryStringValues() {
    const query = window.location.search

    if (!query) {
      return {}
    }

    return (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        const paramArr = param.split('=')

        const key = paramArr[0]
        const value = paramArr[1]

        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''

        return params
      }, {})
  }
}(_))
