(function ($, _) {
  window.vt = window.vt || {}

  vt.Reveal = (function () {
    // Constants
    const CONTENT_SELECTOR = '[data-reveal-content]'
    const TOGGLE_SELECTOR = '[data-reveal-toggle]'
    const MASKED_CLASS = 'is-masked'

    function initialize(el) {
      const $el = $(el)
      const defer = _.includes(_.keys($el.data()), 'revealDefer')
      const built = build($el)

      if (!built && defer) {
        $el.on('ready', build.bind(null, $el))
      }

      return {
        destroy: removeEvents.bind(null, $el)
      }
    }

    function build($el) {
      const height = +$el.data('revealHeight')
      const label = $el.data('revealLabel')
      const $content = $el.find(CONTENT_SELECTOR)

      if ($el.outerHeight() > height) {
        const $toggle = $(`<button class="reveal__toggle action-button" data-reveal-toggle>${label}</button>`)

        $content.css('height', height)

        $el.append($toggle)
        $el.addClass(MASKED_CLASS)

        addEvents($el)

        return true
      }

      return false
    }

    function addEvents($el) {
      $el.one('click', revealContent.bind(null, $el))
      $el.one('click', TOGGLE_SELECTOR, revealContent.bind(null, $el))
    }

    function removeEvents($el) {
      $el.off('click')
      $el.off('click', TOGGLE_SELECTOR)
    }

    function revealContent($el, evt) {
      const $content = $el.find(CONTENT_SELECTOR)
      $content.css('height', 'auto')

      $el.find(TOGGLE_SELECTOR).remove()

      $el.removeClass(MASKED_CLASS)
    }

    return {
      initialize
    }
  }())
}(jQuery, _))
