<template>
  <div
    ref="list"
    class="c-main-menu__dropdown"
  >
    <div class="c-main-menu__dropdown-inner u-flex">
      <div
        v-for="(subsection, i) in section.subsections"

        :key="i"
        class="c-main-menu__dropdown-section"
      >
        <span
          v-if="subsection.title"
          class="c-main-menu__section-title"
        >
          {{ subsection.title }}
        </span>
        <ul
          class="c-main-menu__dropdown-list u-bare-list"
          :class="{
            'no-heading': !subsection.title && anyTitle
          }"
        >
          <li
            v-for="link in subsection.links"
            :key="link.text"
          >
            <a
              class="c-main-menu__dropdown-link"
              :href="link.href"
              :class="{
                'is-active': link.isActive
              }"
              v-bind="link.attributes"
              @click="trackMenuClick(link.text)"
            >
              {{ link.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  props: {
    section: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    anyTitle() {
      return this.section.subsections.some(s => s.title)
    }
  },
  watch: {
    isActive(isActive) {
      if (isActive) {
        disablePageScroll(this.$refs.list)
      } else {
        enablePageScroll(this.$refs.list)
      }
    }
  },
  methods: {
    trackMenuClick(navigationItem) {
      tracker.trackDropdownNavClick({
        event: 'menuDropdownNavigationClick',
        navigationItem
      })
    }
  }
}

</script>
