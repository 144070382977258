(function ($) {
  /* Fix for applying custom design on the select-element */
  $.fn.customSelect = function (flexWidth) {
    // Fixes padding issue in IE
    $(this).each(function () {
      const custom = $(this)
      // custom select already applied return
      if (custom.parent().find('p').length > 0) {
        return
      }

      const text = custom.parent().prepend(`<p>${$(this).find(':selected').text()}</p>`)
      if (flexWidth) {
        custom.css({
          width: '100%'
        })
      } else {
        custom.css({
          width: `${text.outerWidth(true) + 30}px`
        })
      }
      custom.on('change', function () {
        $(this).parent().find('p').text($(this).find(':selected').text())
      })

      custom.on('mousedown', function () {
        $(this).addClass('no-focus')
      })

      custom.on('blur', function () {
        $(this).removeClass('no-focus')
      })
    })
    return this
  }

  /* Switching images (logo) to png if svg is not supported */
  $.fn.replaceSvgWithPng = function (forceReplace) {
    // var forceReplace is used if we need to force a switch
    return $(this).each(function () {
      const img = $(this)
      const supportsSvg = !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect

      if (!supportsSvg || forceReplace) {
        if (img.data('fallback-image')) {
          // replace svg with fallback-image
          img.prop('src', img.data('fallback-image'))
        } else {
          img.prop('src', img.prop('src').replace('.svg', '.png'))
        }
      }
    })
  }

  $.fn.enableFullscreen = function (settings) {
    const options = $.extend({
      relatedElement: 'div:first',
      manipulation: 'append'
    }, settings)

    const view = this
    const closeFullscreenLink = $(`<a href="#" class="fullscreen-close-link"><span>${arlaTranslations.close}</span></a>`)

    function showFullscreen(evt) {
      if (!screenfull.isFullscreen) {
        evt.preventDefault()

        $('html').addClass('fullscreen-mode')

        view.append(closeFullscreenLink)
        screenfull.request(view.get(0))
      }
    }

    function closeFullscreen() {
      $('html').removeClass('fullscreen-mode')

      $('a.fullscreen-close-link:first').detach()
    }

    if (screenfull.enabled) {
      $(this).each(() => {
        $(options.relatedElement)[options.manipulation](`<a href="#" class="fullscreen-link"><span>${arlaTranslations.fullScreenLinkText}</span></a>`)

        $('a.fullscreen-link:first').on('click', showFullscreen)
      })

      screenfull.onchange(() => {
        if (!screenfull.isFullscreen) {
          closeFullscreen()
        }
      })

      closeFullscreenLink.on('click', (evt) => {
        evt.preventDefault()

        screenfull.exit()
      })
    }
  }

  /* ---- Responsive ---- */

  /* Turn alphabetical-nav into select */
  $.fn.linksToSelect = function (settings) {
    return this.each(function () {
      const nav = $(this)
      let selectEl
      let select = nav.data('select-reference') || $('<select>')
      const o = $.extend({
        showNav: true,
        hookupChange: false,
        flexWidth: false
      }, settings)

      // Switching id between elements
      function switchID(fromEl, toEl) {
        // Add Id if there is an id, remove id from nav
        if (fromEl.prop('id')) {
          toEl.prop('id', fromEl.prop('id'))
          fromEl.removeAttr('id')
        }
      }

      if (o.showNav === true) {
        nav.removeClass('hidden')
        select.addClass('hidden')
        switchID(select, nav)
        return
      } if (o.showNav === false && nav.data('select-ready')) {
        nav.addClass('hidden')
        select.removeClass('hidden')
        switchID(nav, select)
        return
      }

      // Populate dropdown with menu items
      nav.find('a').each(function () {
        const el = $(this)
        if (el.parent().hasClass('selected') || el.hasClass('selected')) {
          $('<option />', {
            selected: 'selected',
            value: el.prop('href'),
            text: el.text()
          }).appendTo(select)
        } else {
          $('<option />', {
            value: el.prop('href'),
            text: el.text()
          }).appendTo(select)
        }
      })

      // Create the dropdown base
      select = $('<div class="select" />').append(select)
      switchID(nav, select)

      // Append new select to DOM and apply extra elements
      $(nav).after(select)
      selectEl = select.find('select')
      // If custom select is not fixed fix it
      selectEl.customSelect(o.flexWidth)
      if (o.cssClass && o.label) {
        select.wrap('<fieldset />')
        select = select.parent()
        select.prepend(`<label>${o.label}</label>`)
        // Set Id + for + class
        selectEl.prop('id', `${o.cssClass}-select`)
        select.find('label').prop('for', `${o.cssClass}-select`)
        select.addClass(o.cssClass)
      } else if (o.cssClass) {
        select.addClass(o.cssClass)
      }
      // Hide nav
      nav.addClass('hidden')

      if (o.hookupChange === true) {
        select.change(function () {
          window.location = $(this).find('option:selected').val()
        })
      }
      nav.data({
        'select-reference': select,
        'select-ready': true
      })
    })
  }

  /* Creates a link on samller screens for easy navigating from bottom to top */
  $.fn.topLink = function (speed) {
    if ($('a.top-link').length > 0) {
      return
    }

    return this.each(function () {
      const parent = $(this)
      const link = $(`<a class="top-link" href="#">${arlaTranslations.backToTopLinkText}</a>`)

      parent.after(link)

      $('a.top-link:first').on('click', (e) => {
        $('html, body').animate({
          scrollTop: 0
        }, 'slow')
        // Put focus on top header
        $('h1:first').attr('tabindex', '-1').focus().removeAttr('tabindex')
        return false
      })
    })
  }

  $.fn.hideElementWithAnimation = function (settings) {
    o = $.extend({
      timeout: 5000,
      animationTime: 2000
    }, settings)
    return this.each(function () {
      const scope = $(this)
      setTimeout(() => {
        if ($.fn.transitionSupported()) {
          scope.addClass('hide-animate')
        } else {
          scope.fadeOut(o.animationTime)
        }
      }, o.timeout)
      $(scope).on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd end', () => {
        scope.off('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd end').addClass('hidden')
      })
    })
  }

  /* ---- End ---- */
}(jQuery))
