
(function ($) {
  $.fn.showMorePosts = function () {
    const container = $(this)
    $('.social-media-section .show-more').each(function () {
      $(this).loadMorePosts($(this))
    })
  }

  $(document).ready(function () {
    $(this).showMorePosts()
  })
}(jQuery))
