
import '@/CVIDesignSystem/src/assets/styles/headerForLegacy.scss'
import '@/CVIDesignSystem/src/assets/styles/loginPopupForLegacy.scss'
import '@/CVIDesignSystem/src/assets/styles/shoppingCartForLegacy.scss'

import '@/CVI/WebCore/polyfills'
import Vue from 'vue'
import VueMq from 'vue-mq'
// import Vuelidate from 'vuelidate' it was used on UserLocation, but we don't have UserLocation any more
import VModal from 'vue-js-modal'
import vueUtil from '@/CVI/WebCore/vue-util'
import '@/CVI/WebCore/lib/login-redirect'

// Vanilla scripts
import { stickyHeader } from '@/CVI/WebCore/components/Header'
import grid from '@/CVI/WebCore/components/Grid'

import tracker from '@/CVI/WebCore/core-tracker'


import WebCoreMainMenu from '@/CVI/WebCore/components/Navigation/MainMenu.vue'
import WebCoreServiceMenu from '@/CVI/WebCore/components/Navigation/ServiceMenu/ServiceMenu.vue'
import ProductAdimoButton from '@/CVI/Product/components/AdimoButton.vue'

import PurchaseCustomMealPlanWidget from '@/CVI/Purchase/components/CustomMealPlan/WidgetLoader.vue'

// Vue.use(Vuelidate) it was used on UserLocation, but we don't have UserLocation any more

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 992,
    desktop: Infinity
  }
})

Vue.use(VModal, {
  componentName: 'x-modal'
})

const components = {
  // webcore
  WebCoreMainMenu,
  WebCoreServiceMenu,
  ProductAdimoButton,
  // Purchase
  PurchaseCustomMealPlanWidget
}

vueUtil.mountComponents(components)

stickyHeader()
grid()
tracker.trackGridPageItemClick()
tracker.trackGridProductItemClick()
tracker.milkyWhiteTracking()

if (navigator.userAgent.match(/Trident\/7\./)) {
  import('@/CVI/WebCore/components/outdated-browser-popup').then((popup) => {
    popup.default()
  })
}
// One trust reopen
const cookieSettingsButton = document.querySelector('.cookie-popup-link')
if (cookieSettingsButton) {
  cookieSettingsButton.addEventListener('click', () => {
    if (window.reOpenCookiePopup) {
      window.reOpenCookiePopup()
    }
  })
}
