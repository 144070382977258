import initializer from '@/WebCore/Js/initializer'

/* globals arlaTranslations, disqus_shortname */
// TODO: Approach needs revisiting entirely to base it on the data-js-initialize method.
(function ($) {
  /* Detecting low res recipe images add frames them in a wrapping div */
  $.fn.detectLowResRecipeImage = function () {
    function frameReciepImage(elem) {
      elem.wrap('<div class="low-res-image" />')
    }

    if (this.length) {
      const newImg = new Image()
      let height = 0
      let width = 0
      newImg.src = $(this).prop('src') // creates a new image to get the source height/width
      height = newImg.height
      width = newImg.width

      if (width > 0 && height > 0) {
        if (width < 430 && height < 269) {
          frameReciepImage($(this))
        }
        return this
      }
      $(this).load(function () {
        // the image must be loaded to detect the height and width
        height = newImg.height
        width = newImg.width
        if (width < 430 && height < 269) {
          frameReciepImage($(this))
        }
        return this
      })
    }
  }



  /* Add or remove a recipe from your favourites */
  // $.fn.favoriteRecipe = function (recipeId, action) {
  //  if (action === null) {
  //    action = ($(this).find("a").hasClass('recipe-favorite--add-favorite')) ? 'add' : 'remove';
  //  }

  //  $(this).ajaxProxy({
  //    url: '/WebAppsCustomerWeb/FavoriteButton/HandleForm',
  //    selector: '> a',
  //    replacement: '.recipe-favorite-wrapper',
  //    postData: {
  //      'favoriteRecipeId': recipeId,
  //      'action': action
  //    },
  //    errorClass: 'recipe-favorite-tooltip',
  //    callbackArguments: action,
  //    displayCallback: function(action) {

  //      //Increment added favorites
  //      var scope = this,
  //        counter = $('#counter'),
  //        num = parseInt($.trim(counter.html()), 10);
  //      if (action === 'add') {
  //        counter.html(++num);
  //      } else {
  //        counter.html(--num);
  //      }
  //      scope.off();

  //      //publish event for other functions like GA to hook on
  //      $.publish('save-favorite');

  //      scope.favoriteRecipe($('#recipe-media').data('recipe-id'), ($(this).find('a').hasClass('add-favorite') ? 'add' : 'remove'));
  //      setTimeout(function() {
  //        scope.find('.recipe-favorite-tooltip').addClass('hidden');
  //      }, 4000);
  //    }
  //  });
  // };

  /* Injecting the markup and script needed for disqus to load */
  $.fn.addDisqus = function () {
    const $el = $(this)

    window.disqus_config = function () {
      this.callbacks.onReady = [(function () {
        $el.trigger('ready')
      })]
    }

    if ($(this).length > 0 && typeof disqus_shortname !== 'undefined') {
      $(this).append('<div id="disqus_thread">')
      const dsq = document.createElement('script')
      dsq.type = 'text/javascript'
      dsq.async = true
      dsq.src = `//${disqus_shortname}.disqus.com/embed.js`;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq)
    }
  }

}(jQuery))
