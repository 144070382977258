
(function ($) {
  $.fn.productsDropDownList = function () {
    const $elm = $('#productCategorylist')
    $elm.DropDownInit($elm)
  }

  $(document).ready(function () {
    $(this).productsDropDownList()
  })
}(jQuery));

(function ($) {
  $.fn.DropDownInit = function (elm) {
    const
      options = elm.find('option')
    const $wrapper = elm.parent()
    const productCategory = !!$('body').is('.product-category')
    const $dropwrapper = $('div.category-dropdownlist')
    const $clikedField = $('#selected-value')
    let categoryNumber = 0
    let selOption = ''
    const defaultText = $clikedField.data('placeholder')
    const expandedClass = 'expanded'
    let categoryIsSelected = ''

    $wrapper.on('click', '.show-hide', () => {
      $wrapper.toggleClass(expandedClass)
      if (!productCategory) {
        $dropwrapper.css('max-height', $('body').height() * 0.65)
        $('#productCategorylist').parents('section').css('overflow', 'visible')
      }
    })

    $.each(options, (index, option) => {
      if ($(option).is(':selected')) {
        categoryIsSelected = option.text
        $clikedField.text(categoryIsSelected)
        if (categoryIsSelected != defaultText) selOption = `<h2 class="category-dropdownlist-title"><a class='sel-option show-hide'>${option.text}</a></h2>`
      } else {
        $dropwrapper.append(`<h2 class="category-dropdownlist-title"><a href='${option.value}'>${option.text}</a></h2>`)
      }
      categoryNumber += 1
    })

    if (categoryIsSelected != defaultText) {
      $clikedField.addClass('selected')
      if (categoryNumber > 5) {
        $wrapper.addClass('up')
        $(selOption).insertAfter('div.category-dropdownlist h2:eq(2)')
      }
    }
  }
}(jQuery))
