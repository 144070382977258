(function ($) {
  const openBrandMenuCookie = 'openBrandMenu'

  $.fn.showBrandMenuIfCookie = function (cookie) {
    if ($.cookie(cookie) && $.cookie(cookie) === 'true') {
      $('.nav-trigger')[0].click()
      $.cookie(cookie, 'false', { path: '/' })
    }
  }

  $.fn.brandsInitScripts = function () {
    const container = $(this)

    /* Load more recipes */
    $('.brand .load-recipes').fakeLoadButton()

    /* ---- polyfills.js ---- */
    // Tests if certain CSS is not supported
    if (!$().cssSupport(':nth-child(2n)')) {
      // elements that do not support nth-child
      $().nthFallback([{
        cssClass: 'first-item',
        elements: [
          'body.brand div.product-list li:nth-child(6n+1)',
          'body.brand-start div.product-list a:nth-child(4n+1)'
        ]
      }, {
        cssClass: 'last-item',
        elements: [
          'body.brand div.product-list li:nth-child(6n+6)',
          'body.brand-start div.product-list a:nth-child(4n)'
        ]
      }])
    }

    // Tests if certain CSS is not supported
    if (!$().cssSupport(':nth-of-type(2n)')) {
      // elements that do not support nth-child
      $().nthFallback([{
        cssClass: 'first-item',
        elements: [
          'body.brand .image-teaser a:nth-of-type(3n+1)',
          'body.brand-start .image-teaser a:nth-of-type(2n+1)'
        ]
      }, {
        cssClass: 'last-item',
        elements: [
          'body.brand .image-teaser a:nth-of-type(3n)',
          'body.brand-start .image-teaser a:nth-of-type(2n)'
        ]
      }])
    }
    /* ---- end ---- */
  }

  $(document).on('click', 'a.campaign-link', function (event) {
    event.preventDefault()
    $('#contest-modal form #flavor').val($(this).data('flavorname'))
    $('#contest-modal form #flavortxt').val($(this).data('flavortxt'))
    $('#contest-modal').fadeToggle('slow', 'swing')
  })

  $(document).on('click', '#contest-modal', function (event) {
    if (event.target != this) return
    $('#contest-modal').fadeToggle('slow', 'swing')
  })

  /* event modal submit */
  $(document).on('submit', '#contest-modal form', function (event) {
    event.preventDefault()
    const $form = $(this)
    $.post($form.attr('action'), $form.serialize(), (response) => {
      $('#contest-modal .ihana-modal-content form').hide()

      // $("#contest-modal .modal-content #thanks").html(response);
      $('#contest-modal .ihana-modal-content #thanks').show()
    }).fail((response) => {
      // T�ss� tuunataan virheet
      const errors = []
      $.each(response.responseJSON.errors, (key, val) => {
        $.each(val, (k, v) => {
          errors.push(v)
        })
      })
      $('#contest-modal #errors').html(errors.join('<br>')).show()
    })
  })

  $(document).on('click', '#contest-modal #share', (event) => {
    FB.ui({
      method: 'share',
      display: 'popup',
      mobile_iframe: true,
      quote: $('#contest-modal form #flavortxt').val(),
      href: 'http://www.arla.fi/ihana/'
    }, (response) => { })
  })

  $(document).on('click', '.js-haschildren-item', () => {
    $.cookie(openBrandMenuCookie, 'true', { path: '/' })
  })

  $(document).ready(function () {
    $(this).brandsInitScripts()
    $(this).showBrandMenuIfCookie(openBrandMenuCookie)
  })
}(jQuery))
