<template>
  <svg :class="cssClass">
    <use :xlink:href="icon.symbol" />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: null
    },
    sizeMobile: {
      type: Number,
      default: null
    },
    fill: {
      type: String,
      default: null
    },
    stroke: {
      type: String,
      default: null
    },
    hoverColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    cssClass() {
      return [
        this.size ? `c-icon-${this.size}` : '',
        this.sizeMobile ? `c-icon-${this.sizeMobile}@mobile` : '',
        this.fill ? `c-icon-fill-${this.fill}` : '',
        this.stroke ? `c-icon-stroke-${this.stroke}` : '',
        this.hoverColor ? this.getHoverColor(this.hoverColor) : ''

      ]
    }
  },
  methods: {
    getHoverColor(color) {
      return this.stroke ? `c-icon-stroke-hover-${color}` : `c-icon-fill-hover-${color}`
    }
  }
}
</script>
