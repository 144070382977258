(function ($) {
  $.fn.fakeLoadButton = function () {
    const btn = $(this)
    let url
    let content
    let holder

    btn.each(function (e) {
      $(this).click(function (e) {
        e.preventDefault()
        $(this).parents('footer').prev('.more-recipes').show()
        $(this).hide()
      })
    })
  }
}(jQuery))
