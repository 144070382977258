import ScrollMagic from './Vendors/ScrollMagic';

(function ($) {
  const stickContainer = document.querySelector('.fixed-container')
  if (ScrollMagic && stickContainer) {
    $(() => {
      const container = document.querySelector('.right-section')
      const content = document.querySelector('.left-section')
      const contentImages = content.querySelectorAll('img')
      const productImageContainer = container.querySelector('.right-section-product-image')
      const articleImage = container.querySelector('.news-article-image')
      const productImage = productImageContainer ? productImageContainer.querySelector('img') : null
      let duration
      const limitWidth = 960

      function getDuration() {
        return duration
      }

      function setDuration() {
        duration = content.clientHeight - stickContainer.clientHeight
      }

      function getOffset() {
        if (productImage) {
          return productImageContainer.clientHeight - productImage.clientHeight
        }

        if (articleImage) {
          const articleImageTop = articleImage.getBoundingClientRect().top
          const containerTop = container.getBoundingClientRect().top
          return articleImageTop ? (articleImageTop - containerTop) : 0
        }
        return 0
      }

      function stickInnit() {
        const sceneController = new ScrollMagic.Controller()
        const scene = new ScrollMagic.Scene({
          triggerElement: '.fixed-container',
          duration: getDuration,
          offset: getOffset(),
          triggerHook: 0
        })
          .setPin('.fixed-container')
          .addTo(sceneController)

        if (productImage) {
          productImage.onload = function () {
            scene.offset(getOffset())
          }
        }

        if (contentImages.length) {
          Array.prototype.map.call(contentImages, (image) => {
            image.onload = setDuration
          })
        }

        window.addEventListener('resize', () => {
          scene.removePin(true)
          setDuration()
          scene.offset(getOffset())
          if (window.innerWidth > limitWidth && (getDuration() - 20) > 0 && stickContainer.clientHeight <= window.innerHeight) {
            scene.setPin('.fixed-container')
              .addTo(sceneController)
          }
        })
      }

      if (stickContainer) {
        setDuration()

        if (window.innerWidth > limitWidth && (getDuration() - 20) > 0 && stickContainer.clientHeight <= window.innerHeight) {
          stickInnit()
        } else {
          window.addEventListener('resize',
            function resizeOnce(e) {
              setDuration()
              if (window.innerWidth > limitWidth
                && (getDuration() - 20) > 0
                && stickContainer.clientHeight <= window.innerHeight) {
                window.removeEventListener('resize', resizeOnce)
                stickInnit()
              }
            })
        }
      }
    })
  }
}(jQuery))
