(function ($) {
  window.vt = window.vt || {}

  vt.productInformation = (function () {
    function initialize($element) {
      $('.tab-container', $element).easytabs()
    }

    return {
      initialize
    }
  }())
}(jQuery))
