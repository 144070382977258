<template>
  <div class="service-menu u-flex u-ml--a  u-hidden-print">
    <LanguageSelector
      v-if="model.languageSelector"
      :model="model.languageSelector"
    />
    <QuickSearch
      v-if="model.quickSearch"
      :model="model.quickSearch"
    />
    <ProfileMenu
      v-if="model.profileMenu"
      :model="model.profileMenu"
      :login-model="model.loginPanel"
    />
    <ShoppingCartPanelContainer
      v-if="model.shoppingCartPanel"
      :model="model.shoppingCartPanel"
    />
  </div>
</template>

<script>
import ProfileMenu from './ProfileMenu.vue'
import QuickSearch from './QuickSearch.vue'
import ShoppingCartPanelContainer from './ShoppingCartPanelContainer.vue'

export default {
  components: {
    LanguageSelector: () => import('@/CVI/WebCore/components/Navigation/ServiceMenu/LanguageSelector.vue'),
    ProfileMenu,
    QuickSearch,
    ShoppingCartPanelContainer
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
