
(function ($) {
  $.fn.productsInitScripts = function () {
    const container = $(this)

    $('.product-list-loadmore').each(function () {
      $(this).loadMoreProducts($(this))
    })
  }

  $(document).ready(function () {
    $(this).productsInitScripts()
  })
}(jQuery))
