export const stickyHeader = () => {
  let timeout = null
  const header = document.querySelector('.c-header')
  const { body } = document

  const getScrollDistance = (function () {
    let prevScrollY = 0

    return function (scrollY) {
      const scrollDistance = scrollY - prevScrollY
      prevScrollY = scrollY
      return scrollDistance
    }
  }())

  const getScrollDirection = function (scrollDistance) {
    return scrollDistance >= 0 ? 'down' : 'up'
  }

  const setHeaderPos = function () {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      const distance = getScrollDistance(window.scrollY)
      const direction = getScrollDirection(distance)

      if (window.scrollY > 0) {
        header.classList.add('has-shadow')
      } else {
        header.classList.remove('has-shadow')
      }

      if (distance === 0) {
        return
      }

      if (direction === 'down' && !header.classList.contains('is-hidden') && !header.classList.contains('is-hidden-by-brand') && window.scrollY >= 60) {
        header.classList.add('is-hidden')
        body.classList.add('header-is-hidden')
      }

      if (direction === 'up' && header.classList.contains('is-hidden') && !header.classList.contains('is-hidden-by-brand')) {
        header.classList.remove('is-hidden')
        body.classList.remove('header-is-hidden')
      }
      if (window.scrollY <= 0) {
        header.classList.remove('is-hidden-by-brand')
        header.classList.remove('is-hidden')
        body.classList.remove('header-is-hidden')
      }
    }, 20)
  }

  if (header) {
    if (header.classList.contains('is-hidden-by-brand')) {
      body.classList.add('brand-page')
      body.classList.add('header-is-hidden')
      header.classList.add('is-hidden')
    }
    window.addEventListener('scroll', setHeaderPos)
    window.addEventListener('load', () => {
      body.classList.remove('brand-page')
    })
  }
}

export const hideHeader = () => {
  const header = document.querySelector('.c-header')
  const { body } = document
  body.classList.add('header-is-hidden')
  header.classList.add('is-hidden')
  header.style.visibility = 'hidden'
}

export const showHeader = () => {
  const header = document.querySelector('.c-header')
  const { body } = document
  body.classList.remove('header-is-hidden')
  header.classList.remove('is-hidden')
  header.style.visibility = 'visible'
}
