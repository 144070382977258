<template>
  <div class="c-buy-button__adimo-container u-mb--m u-mt--m u-hidden-print">
    <p v-if="model.text">
      {{ model.text }}
    </p>
    <div class="u-flex u-flex-wrap u-mb--m u-flex-align-center u-hidden-print">
      <div
        v-if="model.widgetButton.type === 'product'"
        class="adimoWidget u-mb--s"
        data-type="lightbox"
        :data-static-touchpoint-identifier="model.widgetButton.touchPointId"
        @click="trackButtonClick(model.widgetButton.touchPointId)"
      >
        <button class="c-button c-button--dark u-flex-shrink-0">
          <Loader
            color="white"
            class="u-mr--xs"
          />
          <Icon
            class="u-mr--xs"
            :icon="iconCart"
            :size="16"
            stroke="white"
          />
          {{ model.widgetButton.text }}
        </button>
      </div>
      <div
        v-if="model.widgetButton.type === 'recipe'"
        class="adimo-container u-mb--s"
        data-type="recipe-widget"
        :data-touchpoint-id="model.widgetButton.touchPointId"
        @click="trackButtonClick(model.widgetButton.touchPointId)"
      >
        <button class="c-button c-button--dark u-flex-shrink-0">
          <Icon
            class="u-mr--xs"
            :icon="iconCart"
            :size="16"
            stroke="white"
          />
          {{ model.widgetButton.text }}
        </button>
      </div>
      <span class="c-buy-button__adimo-logo u-ml--s u-mb--s">
        <img
          :src="model.logo"
          alt="adimo logo"
        >
      </span>
      <span class="u-font-size-medium u-ml--s u-mr--s u-mb--s">
        <a
          :href="model.link.url"
          :rel="model.link.openInNewWindow ? 'noopener' : null"
          :target="model.link.openInNewWindow ? '_blank' : null"
        >
          {{ model.link.text }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import adimoLoader from '@/CVI/Product/lib/adimo-loader'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Loader,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconCart
    }
  },
  mounted() {
    adimoLoader.loadAdimo()
  },
  methods: {
    trackButtonClick(id) {
      tracker.trackAdimoButtonClick({
        event: 'adimoButtonClick',
        touchpointId: id
      })
    }
  }
}
</script>
