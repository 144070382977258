(function ($) {
  function loadImage(el, fn) {
    const img = new Image()
    const src = el.getAttribute('data-src')

    img.onload = function () {
      if (el.parent) el.parent.replaceChild(img, el)
      else el.src = src

      fn ? fn(el) : null
    }
    img.src = src
  }

  function elementInViewport(el) {
    const rect = el.getBoundingClientRect()

    return (
      rect.top >= 0
            && rect.left >= 0
            && (rect.top - (window.innerHeight || document.documentElement.clientHeight) / 4) <= (window.innerHeight || document.documentElement.clientHeight)
    )
  }

  /* Loads images lazy and when in viewport, not on page-load */
  $.fn.lazyLoadImages = function () {
    const images = $(this)
    let loadedImages = []
    const processScroll = function () {
      if (!images.length) {
        // $(window).off('scroll');
        return
      }
      images.each(function (i) {
        const scope = this
        const alredyLoaded = $(scope).data('finished')
        if (elementInViewport(scope) && !alredyLoaded) {
          loadImage(scope, (el) => {
            $(el).data('finished', true)
          })
        } else if (alredyLoaded) {
          // images.splice(images.index(scope), 1);
          loadedImages.push(images.index(scope))
          // console.log(images.index(scope), scope);
        }
      })
      $.each(loadedImages, function (i) {
        images.splice(this - i, 1)
      })
      loadedImages = []
    }

    processScroll()
    $([window, document.body]).on('scroll', processScroll)
  }
}(jQuery))
