import axios from 'axios'

const language = document.documentElement.lang

export default {
  search: {
    baseUrl: `/cvi/search/api/${language}`,
    autocomplete({ query, cancelToken }) {
      return axios.get(`${this.baseUrl}/autocomplete`, {
        cancelToken: cancelToken.token,
        params: {
          query
        }
      })
    }
  },
  createCancelToken() {
    return axios.CancelToken.source()
  },
  couponCode: {
    coupons: `/cvi/webcore/api/${language}/coupons`,
    get(id) {
      return axios.get(`${this.coupons}/${id}`)
    },
    validate(id) {
      return axios.head(`${this.coupons}/${id}`)
    },
    clearon({
      blockId, token, email, phoneNumber, useEmail
    }) {
      return axios.post(`${this.coupons}/clearon`, {
        blockId, email, phoneNumber, useEmail, token
      })
    }
  },
  productCards: {
    baseUrl: `/cvi/products/api/${language}/cards`,
    get({
      take, skip, categoryIds, brandIds, tagUids, brandUids
    }) {
      return axios.post(this.baseUrl, {
        take, skip, categoryIds, brandIds, tagUids, brandUids
      })
    }
  },
  signUp: {
    subscribeUrl: '/webappscustomerweb/api/genericnewsletter/subscribe',
    subscribe({ blockId, email, mailingLists }) {
      return axios.post(this.subscribeUrl, {
        blockId, email, mailingLists
      })
    }
  }
}
