import Proxy from './components/Proxy'

function toPascalCase(kebabCase) {
  const camelCase = kebabCase.replace(/-([a-z])/g, g => g[1].toUpperCase())
  const pascalCase = camelCase[0].toUpperCase() + camelCase.slice(1)

  return pascalCase
}

function parseProps(dataset) {
  const props = {}

  Object.keys(dataset).forEach((prop) => {
    const val = dataset[prop]

    if (val == 'true' || val == 'True') {
      props[prop] = true
    } else if (val == 'false' || val == 'False') {
      props[prop] = false
    } else if (Number(val)) {
      props[prop] = Number(val)
    } else if (val.length > 1
      && ((val[0] == '[' && val[val.length - 1] == ']')
      || (val[0] == '{' && val[val.length - 1] == '}'))) {
      props[prop] = JSON.parse(val)
    } else {
      props[prop] = val
    }
  })

  return props
}

export default {
  mountComponents(components, store, rootElement = document, componentSelector = 'data-vue') {
    if (components) {
      Object.entries(components).forEach(([componentName, component]) => {
        const domElements = Array.from(rootElement.querySelectorAll(`[${componentSelector}="${componentName}"]`))
        if (domElements.length) {
          domElements.forEach((el) => {
            this.mountComponent(el, component, store)
          })
        }
      })
    }
  },
  mountChildComponents(components, store, rootElement) {
    this.mountComponents(components, store, rootElement, 'data-vue-child')
  },
  mountComponent(el, component, store) {
    const props = parseProps(el.dataset)
    props.slotContent = el.innerHTML

    new Proxy({
      store,
      propsData: {
        component,
        props
      }
    }).$mount(el)
  }
}
