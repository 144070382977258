// from https://stackoverflow.com/questions/5796718/html-entity-decode

const decodeEntities = (function () {
  const element = document.createElement('div')

  function decodeHTMLEntities (str) {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
      element.innerHTML = str
      str = element.textContent
      element.textContent = ''
    }

    return str
  }

  return decodeHTMLEntities
}())

export default decodeEntities
