(function ($) {
  window.vt = window.vt || {}

  vt.DynamicContent = (function () {
    const extractProperty = function (o, s) {
      return o[s]
    }

    // Depends on ECMA 5 - use shim for IE8 and older
    const objectReference = function (obj, str) {
      return str.split('.').reduce(extractProperty, obj)
    }

    function initialize(element) {
      if (!element) {
        return
      }

      let handler
      const loadingClass = 'loading'
      const url = $(element).data('url')

      if (!url) {
        $(element).removeClass(loadingClass)
        return
      }

      $.ajax({
        url,
        success(data) {
          $(element).removeClass(loadingClass)

          if (data && data.Data) {
            $(element).empty()
            $(element).append(data.Data)
            $(element).find('*[data-js-initialize]').each(function () {
              try {
                const modules = this.getAttribute('data-js-initialize').split(' ')
                for (let j = 0; j < modules.length; j++) {
                  handler = objectReference(window, modules[j])
                  handler.initialize(this)
                }
              } catch (ex) { }
            })
          }
        },
        error() {
          $(element).removeClass(loadingClass)
        },
        timeout: 2000 // in milliseconds
      })
    }

    return {
      initialize
    }
  }())
}(jQuery))
