(function ($) {
  window.vt = window.vt || {}

  vt.SeoLinks = (function () {
    function initialize(element) {
      $(element).find('.seo-link-groups-show-all').click(function () {
        $(this).parents('.seo-links').find('.seo-link-groups .content-block').show()
        $(this).hide()
      })

      $(element).find('.seo-links-show-all').click(function () {
        $(this).parents('.content-block').find('ul li').show()
        $(this).hide()
      })

      const categories = $(element).find('.seo-link-groups .content-block').length
      if (categories > 4) {
        $(element).addClass('seo-link-over-four')
      }
    }

    return {
      initialize
    }
  }())
}(jQuery))
