(function ($) {
  window.vt = window.vt || {}

  vt.twoSlidesDeck = (function () {
    function setupSlider(deck) {
      const $module = $(deck)
      $module.twentytwenty({
        default_offset_pct: 1
      })

      $module.addClass('slider-loading')
      const deckOffset = $module.offset().top
      $(window).scroll(() => {
        const windowScrollTop = $(window).scrollTop()
        if (windowScrollTop >= deckOffset - 200) {
          $(window).trigger('animate.twentytwenty', 0.65)
        }
      })
    }

    function initialize(deck) {
      setupSlider(deck)
    }

    return {
      initialize
    }
  }())
}(jQuery))
