(function ($, _) {
  window.vt = window.vt || {}

  let googleMapsApiPromise = null

  vt.GoogleMap = function (element, options) {
    const opts = _.merge({
      mapOptions: {
        center: {
          lat: -34.397,
          lng: 150.644
        },
        panControl: true,
        zoom: 6
      },
      focusZoom: 16
    }, options)

    // Init
    let activeMapWindow = null
    let map = null
    let mapsApi = null
    let mapPins = []
    let mapPinListeners = []
    let mapWindows = []

    function initMap(googleMapsApi) {
      mapsApi = googleMapsApi
      map = new mapsApi.Map(element, opts.mapOptions)

      return map
    }

    function clearMapPins() {
      mapPinListeners.forEach((listener) => {
        mapsApi.event.removeListener(listener)
      })

      mapPinListeners = []

      mapPins.forEach(clearMapPin)
      mapPins = []

      mapWindows.forEach((pin) => {
        pin.close()
      })

      mapWindows = []
      activeMapWindow = null
    }

    function clearMapPin(pin) {
      pin.setMap(null)
    }

    function closeInfoWindow() {
      activeMapWindow = null
    }

    function createInfoWindow(pinData, infoWindowOptions) {
      const infoWindowOpts = _.merge({
        template: null,
        offset: {
          x: 0,
          y: 0
        }
      }, infoWindowOptions)

      const infoWindow = new mapsApi.InfoWindow(_.merge({}, _.pick(pinData, 'position'), {
        content: infoWindowOpts.template && infoWindowOpts.template(pinData),
        pixelOffset: new mapsApi.Size(infoWindowOpts.offset.x, infoWindowOpts.offset.y)
      }))

      infoWindow.id = pinData.id

      return infoWindow
    }

    function fitMapBounds(bounds) {
      if (bounds) {
        map.fitBounds(bounds)
      }
    }

    function openInfoWindow(pin, index) {
      if (activeMapWindow) {
        activeMapWindow.close()
      }

      activeMapWindow = mapWindows[index]
      activeMapWindow.open(map)
    }

    function plotMapPin(pinData) {
      const mapPin = new mapsApi.Marker(_.merge({}, pinData, {
        map
      }))

      return mapPin
    }

    function plotMapPins(pinArr, pinOptions) {
      if (pinArr.length === 0) {
        return
      }

      const pinOpts = _.extend({
        infoWindowOptions: null,
        setMapFocus: false,
        pinClickHandler: null
      }, pinOptions)

      try {
        mapPins = pinArr.map(plotMapPin)

        if (pinOpts.infoWindowOptions) {
          mapWindows = pinArr.map(_.partial(createInfoWindow, _, pinOpts.infoWindowOptions))

          mapPinListeners = mapPinListeners
            .concat(mapPins.map(getMapPinListener('click', openInfoWindow)))
            .concat(mapWindows.map(getInfoWindowListener('closeclick', closeInfoWindow)))
        }

        if (pinOpts.pinClickHandler) {
          mapPinListeners = mapPinListeners
            .concat(mapPins.map(getMapPinListener('click', pinOpts.pinClickHandler)))
        }

        if (pinOpts.setMapFocus) {
          if (mapPins.length > 1) {
            fitMapBounds(getMapPinsBounds(mapPins))
          } else {
            map.setCenter(mapPins[0].getPosition())
            map.setZoom(opts.focusZoom)
          }
        }

        return mapPins
      } catch (err) {
        console.error('Unable to plot map pins:', err)

        return []
      }
    }

    function getMapPinListener(eventType, callback) {
      return function (pin, i) {
        return pin.addListener(eventType, callback.bind(null, pin, i))
      }
    }

    function getInfoWindowListener(eventType, callback) {
      return function (infoWindow, i) {
        return infoWindow.addListener(eventType, callback.bind(null, infoWindow, i))
      }
    }

    function getMapPinsBounds(mapPins) {
      if (!mapPins || !mapPins.length) {
        return null
      }

      const bounds = new mapsApi.LatLngBounds()

      mapPins.forEach((pin) => {
        bounds.extend(pin.getPosition())
      })

      return bounds
    }

    return {
      load(apiKey) {
        return loadGoogleMaps(apiKey).then((googleMapsApi) => {
          const mapRef = initMap(googleMapsApi)

          return {
            clearMapPins,
            plotMapPins,
            resize: googleMapsApi.event.trigger.bind(googleMapsApi.event, mapRef, 'resize'),
            setCenter: mapRef.setCenter.bind(mapRef),
            setZoom: mapRef.setZoom.bind(mapRef),
            destroy() {
              clearMapPins()
            }
          }
        })
      }
    }
  }

  function loadGoogleMaps(apiKey) {
    if (googleMapsApiPromise) {
      return googleMapsApiPromise
    }

    const deferred = $.Deferred()

    googleMapsApiPromise = deferred.promise()

    window.initGoogleMapsApi = function () {
      deferred.resolve(window.google.maps)
    }

    const script = document.createElement('script')
    script.setAttribute('src', `${'https://maps.googleapis.com/maps/api/js' + '?v=3.26&key='}${apiKey}&callback=initGoogleMapsApi`)
    document.head.appendChild(script)

    return googleMapsApiPromise
  }
}(jQuery, _))
