(function ($) {
  $(() => {
    let culture = ''

    if ($('#searchLanguage').length) {
      culture = $('#searchLanguage')[0].value
    }

    $('#queryInput').not('.autocomplete-disabled').jzautocomplete(
      '/api/search/autocomplete/', {
        minChars: 3, /* used for performance issues */
        cacheLength: 0,
        extraParams: { culture },
        delay: 200,
        selectFirst: false,
        dataType: 'json',
        scroll: false,
        parse(data) {
          return $.map(data, row => ({ data: row }))
        },
        formatItem(item) {
          if (item) {
            return item
          }
          return false
        }
      }
    ).result((event, item) => {
      if (item) {
        $('#queryInput').val(item)
      }
      $('#searchSubmit').click()
    })
  })
}(jQuery))
