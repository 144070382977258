<template>
  <div class="c-profile-link">
    <Popover
      v-if="model.isAuthenticated"
      v-model="isDropdownVisible"
      classes="c-profile-link__dropdown"
      :placement="'top-start'"
      :container="'.c-profile-link'"
      :auto-close="true"
      :auto-close-delay="20000"
      :offset="2"
    >
      <button
        class="c-service-menu__button"
        :class="{
          'is-opened': isDropdownVisible
        }"
        :href="model.url"
        :title="model.title"
        @click.prevent="openDropdown"
      >
        <Icon
          :icon="iconProfile"
          :size="24"
          stroke="black"
        />
      </button>
      <template slot="popover">
        <div class="c-profile-link__list">
          <ul class="u-bare-list">
            <li>
              <a
                class="c-profile-link__list-link"
                :href="model.myPagesUrl"
                @click="trackProfileMenuLinkClick(myPagesText)"
              >
                {{ myPagesText }}
              </a>
            </li>
            <li>
              <a
                class="c-profile-link__list-link"
                :href="model.settingsUrl"
                @click="trackProfileMenuLinkClick(model.settingsUrlText)"
              >
                {{ model.settingsUrlText }}
              </a>
            </li>
            <li v-if="model.userCockpitUrl">
              <a
                class="c-profile-link__list-link"
                :href="model.userCockpitUrl"
                @click="trackProfileMenuLinkClick(model.userCockpitText)"
              >
                {{ model.userCockpitText }}
              </a>
            </li>
            <li v-if="model.foodMoversPageUrl">
              <a
                class="c-profile-link__list-link"
                :href="model.foodMoversPageUrl"
                @click="trackProfileMenuLinkClick(model.foodMoversPageText)"
              >
                {{ model.foodMoversPageText }}
              </a>
            </li>
            <li>
              <a
                class="c-profile-link__list-link"
                :href="model.logoutUrl"
                @click="trackProfileMenuLinkClick(model.logoutUrlText)"
              >
                {{ model.logoutUrlText }}
              </a>
            </li>
          </ul>
        </div>
      </template>
    </Popover>
    <button
      v-if="!model.isAuthenticated"
      class="c-service-menu__button"
      :class="{
        'is-opened': store.state.isLoginPopupVisible
      }"
      :href="model.url"
      :title="model.title"
      @click.prevent="openLoginPopup"
    >
      <Icon
        :icon="iconLogin"
        :size="24"
        stroke="black"
      />
    </button>
  </div>
</template>

<script>
import iconProfile from '@ds/svg/icons/stroke/single-neutral-actions-check-1.svg'
import iconLogin from '@ds/svg/icons/stroke/single-neutral.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import store from '@/CVI/WebCore/store'
import Popover from '@/CVI/WebCore/components/Popover.vue'
import mountComponent from '@/CVI/WebCore/utils/mount-component-to-body'

const header = document.getElementsByClassName('c-header')[0]

export default {
  components: {
    Icon,
    Popover
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    loginModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconLogin,
      iconProfile,
      isDropdownVisible: false
    }
  },
  computed: {
    myPagesText() {
      return this.model.favoriteRecipesFormat
        ? this.model.favoriteRecipesFormat.replace('{favoriteRecipeCount}', this.model.favoriteRecipeCount)
        : ''
    }
  },
  watch: {
    isDropdownVisible: (visible) => {
      if (visible) {
        header.classList.add('is-profile-menu-opened')
      } else {
        header.classList.remove('is-profile-menu-opened')
      }
    }
  },
  mounted() {
    if (!this.model.isAuthenticated) {
      this.loadLoginPopup()
    }
  },
  methods: {
    openLoginPopup() {
      this.store.state.isLoginPopupVisible = true
      this.trackLoginLinkClick()
    },
    trackLoginLinkClick() {
      tracker.trackSimpleEvent({
        event: 'menuNavigationLogin'
      })
    },
    openDropdown() {
      this.isDropdownVisible = true
    },
    loadLoginPopup() {
      import('@/CVI/WebCore/components/Navigation/ServiceMenu/LoginPanel.vue').then((module) => {
        mountComponent({
          component: module.default,
          props: {
            model: this.loginModel
          }
        })
      })
    },
    trackProfileMenuLinkClick(link) {
      tracker.trackLoggedInMenuLinks({
        event: 'loggedInMenuClick',
        loggedInMenuItemClick: link
      })
    }
  }
}
</script>
