(function ($, _) {
  window.vt = window.vt || {}

  vt.StateMonitor = function (initialState) {
    // Init
    let state = _.isPlainObject(initialState) && initialState || {}
    const callbacks = []

    // Functions
    function getState() {
      return state
    }

    function setState(stateUpdate) {
      if (!_.isPlainObject(stateUpdate)) {
        throw new Error('State update must be a plain object.')
      }

      if (Object.keys(stateUpdate).length) {
        callbacks.forEach((callback) => {
          const newState = _.extend({}, state, stateUpdate)
          const currentState = _.extend({}, state)

          if (checkProps(newState, currentState, callback.props)) {
            callback.fn(
              callback.props ? _.pick(newState, callback.props) : newState,
              callback.props ? _.pick(currentState, callback.props) : currentState
            )
          }
        })

        state = _.extend({}, state, stateUpdate)
      }

      return state
    }

    function checkProps(newState, currentState, props) {
      const propsArr = _.isArray(props) ? props : Object.keys(newState)

      return propsArr.reduce((update, prop) => update || newState[prop] !== currentState[prop], false)
    }

    function subscribe(fn, props) {
      const callback = {
        fn,
        props
      }

      callbacks.push(callback)

      return unsubscribe.bind(null, callback)
    }

    function unsubscribe(fn) {
      _.remove(callbacks, fn)
    }

    return {
      getState,
      setState,
      subscribe
    }
  }
}(jQuery, _))
