// see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_link_role
(function ($) {
  $.fn.accessibleLink = function () {
    const sap = {
      ui: {
        keycodes: {
          SPACE: 32,
          ENTER: 13
        }
      }
    }

    function navigateLink(evt) {
      if (evt.type == 'click'
        || evt.keyCode == sap.ui.keycodes.SPACE
        || evt.keyCode == sap.ui.keycodes.ENTER) {
        const ref = evt.target != null ? evt.target : evt.srcElement
        if (ref) window.open(ref.getAttribute('data-href'), '_self')
      }
    }

    this.on('keydown', navigateLink)

    return this
  }
}(jQuery))
