(function ($, _) {
  window.vt = window.vt || {}

  /**
   * NOTE: This temporary reimplementation is subject to change post 'my pages' updates.
   *  Ideally we should revisit the HTML posting functionality, simplify and split the
   *  'delegateRating' into its own module to avoid inert form elements being present on
   *  page, this will need to be revisited once final functionality has been specified.
   */

  vt.RecipeRating = (function () {
    let instances = []

    const { UrlHelpers } = vt

    function initialize(element) {
      const instance = createInstance($(element))
      instances.push(instance)

      return {
        destroy() {
          instance.destroy()
          _.remove(instances, instance)
        },
        update: instance.update
      }
    }

    function getRecipeRating(recipeId) {
      if (!typeof recipeId === 'number') {
        return
      }

      // NOTE: Note from previous implementation.
      // Arlareciperating is set serverside upon rating
      // it joins all sitewide recipe id ratings in a string separated by "&", like this:
      // 2240684715=5&781783106=4&404488722=5
      const recipeRatingsString = decodeURIComponent(getCookieByName('Arlareciperating'))
      let ratingValue

      recipeRatingsString.split('&').forEach((part) => {
        const item = part.split('=')

        if (Number(item[0]) === recipeId) {
          ratingValue = +item[1]
          return false
        }
      })

      return ratingValue
    }

    function getCookieByName(name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)

      if (parts.length === 2) {
        return parts.pop().split(';').shift()
      }
    }

    function createInstance($element) {
      const recipeId = $element.data('recipeId')
      const touchclick = 'touchstart click'
      const rating = getRecipeRating(recipeId)

      if (rating) {
        showRating(rating)
      } else {
        addEvents()
      }

      function addEvents() {
        $element.on(touchclick, showRatingModal)
      }

      function removeEvents() {
        $element.off(touchclick)
      }

      function showRating(rating) {
        $element.find('[data-rating]').text(rating)
        $element.find('[data-feedback]').show()
      }

      function showRatingModal(evt) {
        evt.preventDefault()
        evt.stopPropagation()

        const $modal = $('<section class="recipe-rating-modal" />')
        const templateHtml = $('#recipe-rating-form-template').first().html()
        $modal.html(templateHtml)

        const modalInstance = vt.RecipeRatingModal.initialize($modal[0])

        if (vt.Speck) {
          vt.Speck.show($modal, () => {
            modalInstance.destroy()
          })
        }
      }

      function update(updatedContent, rating) {
        $element.html(updatedContent)
        showRating(rating)
      }

      function destroy() {
        removeEvents()
      }

      return {
        destroy,
        update
      }
    }

    function destroyAll() {
      instances.forEach((instance) => {
        instance.destroy()
      })

      instances = []
    }

    function updateAll(updatedContent, rating) {
      instances.forEach((instance) => {
        instance.update(updatedContent.cloneNode(true), rating)
      })
    }

    return {
      initialize,
      destroyAll,
      updateAll
    }
  }())

  vt.RecipeRatingModal = (function () {
    let instances = []
    const { UrlHelpers } = vt

    function initialize(modal) {
      const instance = createInstance($(modal))
      instances.push(instance)

      return {
        destroy() {
          instance.destroy()
          _.remove(instances, instance)
        },
        update: instance.update
      }
    }

    function createInstance($element) {
      const recipeId = $element.data('recipeId')

      addEvents()

      function addEvents() {
        $element.on('change', 'input', setRating)
        $element.on('click', '.recipe-rating__submit', sendRating)
      }

      function removeEvents() {
        $element.off('change')
        $element.off('click')
      }

      function setRating(evt) {
        const rating = +evt.currentTarget.value

        $element.find('input').each((index, item) => {
          const $itemLabel = $(`label[for=${$(item).attr('id')}]`)
          if (item === evt.currentTarget) {
            $itemLabel.addClass('rating-star--full')
          } else {
            item.checked = false
            const itemRating = +item.value
            if (itemRating < rating) {
              $itemLabel.addClass('rating-star--full')
            } else {
              $itemLabel.removeClass('rating-star--full')
            }
          }
        })
      }

      function sendRating(evt) {
        if (document.querySelector('.rating-star input:checked') === null) {
          return
        }
        const rating = +document.querySelector('.rating-star input:checked').value
        const data = _.extend({ isAjaxCallback: 'true' },
          $element.find('input')
            .toArray()
            .reduce(getRatingData, {}))

        request($element.find('form.recipe-rating-form').attr('action'), data)
          .then(sendRatingSuccessHandler(rating))
      }

      function sendRatingSuccessHandler(rating) {
        return function (html) {
          const $updatedContent = $(html).find('.recipe-rating__inner')
          vt.RecipeRating.updateAll($updatedContent[0], rating)
          vt.RecipeRating.destroyAll()
          removeEvents()
          const $title = $element.find('.recipe-rating__title')
          const success = $title.data('rating-success')
          $title.text(success)
          $element.find('.recipe-rating__submit').remove()
        }
      }

      function update(updatedContent, rating) {
        $element.html(updatedContent)
      }

      function destroy() {
        removeEvents()
      }

      return {
        destroy,
        update
      }
    }

    function destroyAll() {
      instances.forEach((instance) => {
        instance.destroy()
      })

      instances = []
    }

    function request(url, data) {
      const deferred = $.Deferred()

      $.ajax({
        type: 'POST',
        url,
        data,
        dataType: 'html',
        success: deferred.resolve.bind(deferred),
        error: deferred.reject.bind(deferred)
      })

      return deferred.promise()
    }

    function getRatingData(inputData, input) {
      const $input = $(input)
      const update = {}

      update[$input.attr('name')] = $input.is(':checked') ? 'on' : 'off'

      return _.extend(update, inputData)
    }

    return {
      initialize
    }
  }())
}(jQuery, _))
