(function ($) {
  window.vt = window.vt || {}

  vt.ToggleReveal = (function () {
    function initialize(element) {
      const $element = $(element)
      const $toggleElement = $(`#${$element.attr('aria-controls')}`)
      const hiddenText = $element.data('toggle-reveal-text')
      const visibleText = $element.text()
      const isVisible = $toggleElement.attr('aria-hidden') === 'false'

      const toggleVisiblity = setVisibility(hiddenText, visibleText)
      toggleVisiblity($element, $toggleElement, isVisible)

      const eventHandlers = addEvents($element, $toggleElement, _.partial(toggleVisiblity, $element, $toggleElement))

      return {
        destroy: removeEvents.bind(null, $element, eventHandlers)
      }
    }

    function addEvents($element, $toggleElement, toggleVisibility) {
      const toggleHandler = _.partial(toggle, $toggleElement, toggleVisibility)

      $element.on('click', toggleHandler)

      return {
        toggleHandler
      }
    }

    function removeEvents($element, eventHandlers) {
      $element.off('click', eventHandlers.toggleHandler)
    }

    function setVisibility(hiddenText, visibleText) {
      return function ($element, $toggleElement, visible) {
        if (hiddenText) {
          $element.text(visible ? hiddenText : visibleText)
        }

        $toggleElement.attr('aria-hidden', !visible)
        $toggleElement.toggle(visible)
      }
    }

    function toggle($toggleElement, toggleVisibility, evt) {
      const isVisible = $toggleElement.attr('aria-hidden') === 'false'

      toggleVisibility(!isVisible)
    }

    return {
      initialize
    }
  }())
}(jQuery))
