// export simple vuex-like syntax

export default {
  create(store) {
    return {
      state: store.state,
      getters: Object.keys(store.getters || {}).reduce((getters, key) => {
        Object.defineProperty(getters, key, {
          get() {
            return store.getters[key](store.state, getters)
          }
        })
        return getters
      }, {}),
      async dispatch(action, payload) {
        await store.actions[action](this, payload)
      }
    }
  }
}
