const { hash } = window.location

// to top right away
if (hash) scroll(0, 0)
// void some browsers issue
// setTimeout(function () { scroll(0, 0); }, 1);

$(window).load(() => {
  // *only* if we have anchor on the url
  if (hash && !hash.startsWith('#login') && $(hash).length) {
    // smooth scroll to the anchor id
    const HEADER_HEIGHT = 60
    $('html, body').animate({
      scrollTop: `${$(hash).offset().top - HEADER_HEIGHT}px`
    }, 2000)
  }
})
