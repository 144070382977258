/* globals arlaTranslations, console */
// TODO: Approach needs revisiting entirely to base it on the data-js-initialize method.
(function ($) {
  $(window).load(function () {
    const container = $(this)
    const recipeMedia = $('#recipe-media')
    const recipeId = recipeMedia.data('recipe-id')

    /* ---- general.js ---- */

    /* ---- recipe.js ---- */
    $('body.recipe').find('#image').detectLowResRecipeImage()
    $('body.start').find('.recipe-push').find('img').detectLowResRecipeImage()

    $('.disqus').each(function () {
      if ($(this).css('display') != 'none') {
        $(this).addDisqus()
      }
    })

    $('.facet-result-recipe__recipe-preamble--activator').on('click', function (e) {
      const
        $parent = $(this).parent()
      const $target = $(`#${$(this).data().target}`)
      $parent.toggleClass('activated')
      $target.toggleClass('activated')
      $(this).toggleClass('activated')

      e.preventDefault()
    })
  })

  if ($('body.recipe .recipe-label--prep-time').length) {
    $('body.recipe .recipe-label--prep-time').clone().insertAfter('.recipe-label--prep-time').addClass('recipe-label--servings')
      .find('img:first-of-type')
      .remove()
  }
  $('body.recipe .recipe-label--servings').find('span').text($('.servingsSelect option:selected').text())
}(jQuery))
