/* global console, jQuery, _, angular */

(function (_, $, angular) {
  if (angular) {
    const UtilsModule = angular.module('UtilsModule', [])

    UtilsModule.config(['$httpProvider', function ($httpProvider) {
      $httpProvider.defaults.headers.delete = { 'Content-Type': 'application/json' }
    }])

    // Factories
    UtilsModule.factory('ControllerUtils', () => {
      function getViewModelValue(vm, key) {
        if (!vm.hasOwnProperty(key)) {
          throw new Error(`Unable to get ViewModel value, "${key}" is an unknown property.`)
        }

        return vm[key]
      }

      function setViewModelValue(vm, key, value) {
        if (!vm.hasOwnProperty(key)) {
          throw new Error(`Unable to set ViewModel value, "${key}" is an unknown property.`)
        }

        return vm[key] = value
      }

      return {
        getViewModelValue,
        setViewModelValue
      }
    })

    UtilsModule.factory('AsyncUtils', () => {
      function getAsyncState(currentState, data, err) {
        const newData = data || _.get(currentState, 'data', null)
        const loading = currentState ? !(data || err) : false
        const newErr = err || (data ? null : _.get(currentState, 'errors', null))

        return {
          data: newData,
          loading,
          error: newErr,
          get hasData() { return Boolean(!this.loading && this.data) },
          get hasErr() { return Boolean(!this.loading && this.err) },
          timestamp: Date.now()
        }
      }

      return {
        getAsyncState
      }
    })

    UtilsModule.factory('HttpUtils', [
      '$rootScope',
      '$compile',
      '$http',
      '$q',
      function HttpUtils(
        $rootScope,
        $compile,
        $http,
        $q
      ) {
        const antiForgeryToken = vt.Utils.getAntiForgeryToken()
        if (antiForgeryToken !== '') {
          $http.defaults.headers.common['X-XSRF-Token'] = antiForgeryToken
        }

        // Composed Functions
        const extractData = _.partial(_.get, _, 'data')

        function extractError(err) {
          const errorMessage = _.get(err, 'data.message') || _.get(err, 'statusText') || _.get(err, 'message')
          // var $errorElem = $('<div data-utils-error data-error-message="' + errorMessage + '" />');
          // var $errorScope = $rootScope.$new(true);

          // $compile($errorElem[0])($errorScope);

          // vt.Notification.show($errorElem, $errorScope.$destroy.bind($errorScope));

          return $q.reject(errorMessage)
        }

        function get() {
          const args = Array.prototype.slice.apply(arguments)

          return $http.get.apply($http, args)
            .then(extractData)
            .catch(extractError)
        }

        function put() {
          const args = Array.prototype.slice.apply(arguments)

          return $http.put.apply($http, args)
            .then(extractData)
            .catch(extractError)
        }

        function del() {
          const args = Array.prototype.slice.apply(arguments)

          return $http.delete.apply($http, args)
            .then(extractData)
            .catch(extractError)
        }

        function post() {
          const args = Array.prototype.slice.apply(arguments)

          return $http.post.apply($http, args)
            .then(extractData)
            .catch(extractError)
        }

        function stub(data, err) {
          return $q((resolve, reject) => {
            const fn = data ? resolve : reject

            setTimeout(fn.bind(null, {
              data: data || err
            }), 3000)
          })
            .then(extractData)
            .catch(extractError)
        }

        return {
          get,
          put,
          delete: del,
          post,
          stub
        }
      }
    ])

    // Directives
    UtilsModule.directive('utilsError', () => ({
      template: '<p class="notification__error">{{ UtilsError.errorMessage }}</p>',
      restrict: 'A',
      scope: {
        errorMessage: '@'
      },
      bindToController: true,
      controllerAs: 'UtilsError',
      controller: function UtilsErrorCtrl() {
        const vm = this

        // Lifecycle Methods
        this.$onInit = _.noop
        this.$onDestroy = _.noop
      }
    }))

    UtilsModule.directive('ngClickout', [
      '$document',
      '$parse',
      function ngClickout(
        $document,
        $parse
      ) {
        const pointerEvtName = 'touchstart click'

        return function ($scope, element, attrs) {
          // Events
          $document.on(pointerEvtName, verifyTarget)
          element.on('$destroy', destroy)

          function verifyTarget(evt) {
            const evtTarget = evt.touches ? evt.touches[0].target : evt.target

            if (!(evtTarget === element || element.find(evtTarget).length)) {
              const fn = $parse(attrs.ngClickout)

              $scope.$apply(() => {
                fn($scope, { $event: evt })
              })
            }
          }

          function destroy() {
            $document.off(pointerEvtName, verifyTarget)
          }
        }
      }
    ])

    UtilsModule.directive('ngKeyenter', [
      '$parse',
      function ngKeyenter(
        $parse
      ) {
        return function ($scope, element, attrs) {
          // Events
          element.on('$destroy', destroy)
          element.on('keypress', handler)

          function handler(evt) {
            if (evt.keyCode === 13) {
              const fn = $parse(attrs.ngKeyenter)

              $scope.$apply(() => {
                fn($scope, { $event: evt })
              })
            }
          }

          function destroy() {
            element.off('keypress', handler)
          }
        }
      }
    ])

    UtilsModule.directive('ngFocusout', [
      '$parse',
      '$timeout',
      function ngFocusout(
        $parse,
        $timeout
      ) {
        return function ($scope, element, attrs) {
          // Events
          element.on('focusout', focusOut)
          element.on('$destroy', destroy)

          function focusOut(evt) {
            $timeout(() => {
              const fn = $parse(attrs.ngFocusout)

              $scope.$apply(() => {
                fn($scope, { $event: evt })
              })
            }, 250)
          }

          function destroy() {
            element.off('focusout')
          }
        }
      }])
  }
}(_, jQuery, window.angular))
