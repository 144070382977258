
(function ($) {
  /* Add a link that toggles the search filter */
  $.fn.toggleFilter = function (settings) {
    const scope = $(this)
    o = $.extend({
      showFilter: true
    }, settings)
    if (scope.length === 0) {
      return
    }

    if (!scope.data('ready')) {
      scope.each(() => {
        const collapsibleContent = $('.search-filter').addClass('filter-ready')
        const collapsibleLink = $(`<a href="#" class="filter-toggle">${arlaTranslations.showAdvancedRecipeSearchFilterLinkText}</a>`)
        const main = $('.main')

        // events
        collapsibleLink
          .on('collapse', () => {
            collapsibleLink
              .removeClass('expanded-link')
              .addClass('collapsed-link')
              .text(arlaTranslations.showAdvancedRecipeSearchFilterLinkText)
            collapsibleContent
              .addClass('hidden')
              .removeClass('expanded')
              .attr('aria-hidden', true)
            main.removeClass('expanded-filter')
          })
          .on('expand', () => {
            collapsibleLink
              .removeClass('collapsed-link')
              .addClass('expanded-link')
              .text(arlaTranslations.hideAdvancedRecipeSearchFilterLinkText)
            collapsibleContent
              .removeClass('hidden')
              .addClass('expanded')
              .attr('aria-hidden', true)
            main.addClass('expanded-filter')
          })
          .click(function () {
            if (collapsibleContent.hasClass('expanded')) {
              $(this).trigger('collapse')
              sessionStorage.setItem('filterOpen', 0)
              scope.data('clickedOpen', false)
            } else {
              $(this).trigger('expand')
              sessionStorage.setItem('filterOpen', 1)
              scope.data('clickedOpen', true)
            }
            return false
          })

        main.on('click', (e) => {
          const target = $(e.target)
          // if both are false, then true and close descriptionbox
          if (!($.contains(collapsibleContent[0], e.target) || target.is(collapsibleContent[0])) // only if click is outside filter
            && matchMedia('only screen and (max-width: 690px)').matches // only if width is less then 690
            && main.hasClass('expanded-filter')) { // only if filter is expanded
            sessionStorage.setItem('filterOpen', 0)
            scope.data('clickedOpen', false)
            collapsibleLink.trigger('collapse')
            return false
          }
        })

        $('.search').append(collapsibleLink)
        scope.data({
          ready: true, // run only once
          toggleFilter: collapsibleLink
        })
        if (window.location.href.indexOf('sfl=true') > 0) {
          sessionStorage.setItem('filterOpen', 1)
          o.showFilter = true
        }
      })
    }
    if (o.showFilter || window.sessionStorage && parseInt(sessionStorage.getItem('filterOpen'), 10)) {
      scope.data('toggleFilter').trigger('expand')
    } else if (!scope.data('clickedOpen')) {
      scope.data('toggleFilter').trigger('collapse')
    }
  }

  /* Save expanded filters for opening again after page-reload */
  $.fn.saveExpandedFilterState = function () {
    const facet = $.parseJSON(sessionStorage.getItem('facet')) || {}
    $(this).on('click', function () {
      facet[$(this).data('facet-id')] = $(this).hasClass('expanded-link')
      sessionStorage.setItem('facet', JSON.stringify(facet))
    })
  }

  /* Get saved expanded filters for opening again after page-reload */
  $.fn.getExpandedFilterState = function () {
    if (sessionStorage.length > 0) {
      const facet = $.parseJSON(sessionStorage.getItem('facet'))
      const headers = $(this)
      if (facet) {
        for (const key in facet) {
          if (facet[key] && headers.filter(`.${key}`).not('.selected-filter-choice').length) {
            headers.filter(`.${key}`).not('.selected-filter-choice').trigger('expand')
          }
        }
      }
    }
  }

  $.fn.clearSessionStorage = function (event) {
    $(this).on(event, () => {
      sessionStorage.clear()
    })
  }
}(jQuery))
