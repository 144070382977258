/* global console, jQuery, _, angular */

(function (_, angular) {
  if (angular) {
    // Module
    const Filters = angular.module('FiltersModule', [])

    // Filters
    Filters.filter('kebabCase', () => function (str) {
      return _.kebabCase(str)
    })

    Filters.filter('formatReplace', () => function (value, formatStr) {
      const values = _.isArray(value) ? value : [value]

      if (formatStr) {
        return values.reduce((str, currentValue, i) => str.replace(`{${i}}`, currentValue), formatStr)
      }

      return values.toString()
    })

    Filters.filter('take', () => function (value, amount) {
      return _.take(value, amount)
    })
  }
}(_, window.angular))
