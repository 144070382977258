(function ($) {
  window.vt = window.vt || {}

  vt.SelectLink = (function () {
    function initialize(select) {
      const $select = $(select)

      const eventHandlers = addEvents($select)

      return {
        destroy: removeEvents.bind(null, $select, eventHandlers)
      }
    }

    function addEvents($select) {
      const selectChangeHandler = _.partial(setLocation, $select)

      $select.on('change', selectChangeHandler)

      return {
        selectChangeHandler
      }
    }

    function removeEvents($select, eventHandlers) {
      $select.off('change', SELECTOR, eventHandlers.selectChangeHandler)
    }

    function setLocation($select, evt) {
      window.location = $select.val()
    }

    return {
      initialize
    }
  }())
}(jQuery))
