/* global console, jQuery, _, angular */

(function ($, _, angular) {
  // Module
  const CountrySelectorModule = angular.module('CountrySelectorModule', [
    'UtilsModule'
  ])

  // Controllers
  CountrySelectorModule.controller('CountrySelectorNavCtrl', [
    '$rootScope',
    '$scope',
    'ControllerUtils',
    function CountrySelectorNavCtrl(
      $rootScope,
      $scope,
      ControllerUtils
    ) {
      const vm = this

      // Scope Properties
      vm.isVisible = false

      // Composed Functions
      const setIsVisible = _.partial(ControllerUtils.setViewModelValue, vm, 'isVisible')

      // Scope Methods
      vm.show = show
      vm.hide = setIsVisible.bind(vm, false)

      // Lifecycle Methods
      this.$onInit = _.noop
      this.$onDestroy = _.noop

      function show(evt) {
        const toggle = !vm.isVisible

        evt.preventDefault()
        setIsVisible(toggle)
      }
    }
  ])

  // NOTE: Registers angular modules for bootstrapping.
  Array.prototype.push.apply(window.angularModules, [
    'CountrySelectorModule'
  ])
}(jQuery, _, angular))
