
(function ($) {
  $.fn.themesInitScripts = function () {
    const container = $(this)
    // Tests if certain CSS is not supported
    if (!$().cssSupport(':nth-of-type(2n)')) {
      // elements that do not support nth-child
      $().nthFallback([
        {
          cssClass: 'first-item',
          elements:
          [
            'body.theme .image-teaser a:nth-of-type(3n+1)'
          ]
        },
        {
          cssClass: 'last-item',
          elements:
          [
            'body.theme .image-teaser a:nth-of-type(3n)',
            '.featured-thumbnail a:nth-of-type(2n+2)'
          ]
        }
      ])
    }
  }

  $(document).ready(function () {
    $(this).themesInitScripts()
  })
}(jQuery))
