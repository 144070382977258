(function ($) {
  window.vt = window.vt || {}
  vt.initMain = (function () {
    function initialize(elem) {
      const
        container = $(this)
      const $darkElements = null
      const $body = $('body')
      const smallScreen = 640

      $body.addClass('loaded')

      /* ---- form-submit.js  ---- */
      container.keySubmit()
      $('.submit-on-change').add().submitOnChange()

      /* ---- polyfill.js ---- */
      // Test if date is suported
      $('.datepicker').each(function () {
        $(this).keydown((e) => {
          e.preventDefault()
        })
        const format = $(this).data('datepickerformat')
        if (format === undefined || format === '') {
          $(this).datepicker()
        } else {
          $(this).datepicker({
            dateFormat: format
          })
        }
      })

      /* javascript links */
      $(document).on('click', '.js-link', function () {
        document.location.href = $(this).attr('data-href')
      })

      /* --- accessible links ----*/
      $('.js-link').accessibleLink()


      /* ---- general.js ---- */
      $('a.logo > img, header > img').replaceSvgWithPng(false)

      /* ---- lazyoad.js ---- */
      $('img.lazy').lazyLoadImages()

      // Toggle menu
      const $mainNav = $('#main-nav')
      const $navHolder = $('#main-nav').closest('.main-navholder')
      const subNavActiveClass = 'open'
      const selectedItemClass = 'selected'
      const $selectedItem = $mainNav.children(`.${selectedItemClass}`)
      const subMenuToggleClass = 'toggled'

      $body.prepend('<div class="page-overlay" />')

      const pageOverlay = $('.page-overlay')
      const overlayToggleClass = 'show'
      const bodyClass = 'nav-open'

      $mainNav.on('mouseenter', '.nav-item', function () {
        if ($('html').hasClass('no-touch')) {
          $(this).siblings('li').removeClass(subMenuToggleClass)
          $selectedItem.removeClass(selectedItemClass)
        }
      })

      $mainNav.on('mouseleave', '.nav-item', () => {
        if ($('html').hasClass('no-touch')) {
          $selectedItem.addClass(selectedItemClass)
        }
      })

      $mainNav.on('click', '.nav-item', function (e) {
        if ($('html').hasClass('touch')) {
          const $subnav = $(this).find('.sublevel')
          const $siblingsSubnav = $(this).siblings().find('.sublevel')
          const $self = $(this)

          $subnav.addClass('animated')
          $siblingsSubnav.addClass('animated')
          setTimeout(() => {
            $self.siblings().removeClass(subMenuToggleClass)
            $self.toggleClass(subMenuToggleClass)

            if ($self.hasClass(subMenuToggleClass)) {
              $navHolder.addClass(subNavActiveClass)
              pageOverlay.addClass(overlayToggleClass)
              $selectedItem.removeClass(selectedItemClass)
            } else {
              pageOverlay.removeClass(overlayToggleClass)
              $navHolder.removeClass(subNavActiveClass)
              $selectedItem.addClass(selectedItemClass)
            }
          }, 500)

          $subnav.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', (e) => {
            $subnav.removeClass('animated')
            $siblingsSubnav.removeClass('animated')
          })

          if ($(e.target).hasClass('has-sublevel') || $(e.target).parent().hasClass('has-sublevel')) e.preventDefault()
        }

        /* ripple link effect */
        const $targetLink = $(this).find('> .nav-link')
        rippleLink(e, $targetLink)
      })

      pageOverlay.on('click', () => {
        $body.removeClass('nav-open')
        $mainNav.children('.nav-item').removeClass(subMenuToggleClass)
        $selectedItem.addClass(selectedItemClass)
        pageOverlay.removeClass(overlayToggleClass)
      })

      // Cart preview
      $('.shopping-cart').click(function () {
        $body.toggleClass('cart-dropmenu-open')
        $(this).closest('.has-dropmenu').toggleClass('active')
      })


      // Toggle navigation
      $('.nav-trigger').click(function (e) {
        e.preventDefault()
        $subnav = $selectedItem.find('.sublevel')
        $subnav.addClass('animated')

        setTimeout(() => {
          if ($body.hasClass('nav-open')) {
            $body.removeClass('nav-open')
            $mainNav.children('.nav-item').removeClass(subMenuToggleClass)
            pageOverlay.removeClass(overlayToggleClass)
            $navHolder.removeClass(subNavActiveClass)
            $selectedItem.addClass(selectedItemClass)
          } else {
            $body.addClass('nav-open')
            pageOverlay.addClass(overlayToggleClass)
            $mainNav.children('.nav-item').filter('.selected').addClass('toggled')
            $navHolder.addClass(subNavActiveClass)
          }
        }, 300)

        $subnav.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', (e) => {
          $subnav.removeClass('animated')
        })

        /* ripple link effect */
        rippleLink(e, $(this))
      })

      // Toggle dropdown list
      $('.placeholder').click(function () {
        $(this).parent().find('.link-list').toggleClass('hidden')
      })


      // Toggle Search
      $('#js-search-trigger').click(() => {
        $('body').toggleClass('search-active')
      })

      $('#close-search').click(() => {
        $('body').removeClass('search-active')
      })

      // Expand - Collapse
      $('.expand-trigger').click(function () {
        $(this).closest('.expandable').find('.expanded-content').slideToggle()
        $(this).toggleClass('active')
      })

      // Enable switch BTN and change bg-img
      $('.switch-btn').click(function () {
        $(this).toggleClass('enabled')
      })

      // Cart buttons
      $('.add-item').click(function () {
        $(this).toggleClass('added')
      })

      $('.remove-item').click(function () {
        $(this).closest('.cart-item-container').remove()
      })

      // Toggle add-recipe-to-cart btn
      $('.add-recipe-to-cart').click(function () {
        $(this).toggleClass('added')
      })

      // Show-hide search filter
      $('.filter-toggle').click(function () {
        if ($('body').hasClass('filter-active')) {
          $('body').removeClass('filter-active')
          $('.search-filter').slideUp()
          $(this).html('Show filters')
        } else {
          $('body').addClass('filter-active')
          $('.search-filter').slideDown()
          $(this).html('Hide filters')
        }
      })

      // Make filters appear active
      $('.search-filter .tag').click(function () {
        $(this).toggleClass('selected')
      })

      // Get height on slider viewport and apply it to .slider-overlay
      const sliderHeight = $('.bx-viewport').height()
      $('.list-overlay').css('min-height', `${sliderHeight}px`)

      if ($('.faq-deck').length > 0) {
        // Expand-collapse for faq
        const expandedClass = 'expanded'
        const $expButtons = $('.faq-list .content-block > div > h3')

        if (location.hash !== '') {
          $(location.hash).find('h3').addClass(expandedClass)
        }

        $expButtons.on('click', function () {
          $expButtons.not(this).removeClass(expandedClass)
          $(this).toggleClass(expandedClass)

          const anchor = $(this).parent().attr('id')

          if (history.pushState) {
            history.pushState(null, null, `#${anchor}`)
          } else {
            location.hash = `#${anchor}`
          }
        })
      }

      $('.focuspoint').focusPoint()

      // Smooth scroll to the anchor id from querystring parameter
      function getUrlVars() {
        const vars = []; let
          hash
        const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')
        for (let i = 0; i < hashes.length; i++) {
          hash = hashes[i].split('=')
          vars.push(hash[0])
          vars[hash[0]] = hash[1]
        }
        return vars
      }

      if (getUrlVars().anchor) {
        const $target = $(`#${getUrlVars().anchor}`)
        if ($target.length) {
          $('html,body').animate({
            scrollTop: $target.offset().top
          }, 1000)
        }
      }

      // smooth scroll to the anchor id on link click
      $('a[href*="#"]:not([href="#"])').click(function (e) {
        if ($(this).hasClass('no-scrolling')) {
          return
        }
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          const $target = $(this.hash)
          if ($target.length) {
            let top = $target.offset().top
            if ($('.c-header').length) {
              top = $target.offset().top - 60 // header height is fixed, 60 pixels
            }
            $('html,body').animate({
              scrollTop: top
            }, 1000)
            return false
          }
        }
      })

      $('.ripple-link, .action-link, .sublevel .nav-item, .mobile-navigation-items li,'
        + 'a.action-button, .right-section .nav-item > a, .right-section .nav-item label,'
        + '.social-link, .service-nav > .nav-item > a, .product-list-loadmore > a, .filter-list a, .add-to-cart-link, .search-tabs-item').click(function (e) {
        rippleLink(e, $(this))
      })

      // ripple link effect
      function rippleLink(e, $target) {
        let ink; let d; let x; let
          y

        if ($target.find('.ink').length === 0) {
          $target.prepend("<span class='ink'></span>")
        }

        ink = $target.find('.ink')
        ink.removeClass('animate')

        if (!ink.height() && !ink.width()) {
          d = Math.max($target.outerWidth(), $target.outerHeight())
          ink.css({ height: d, width: d })
        }

        x = e.pageX - $target.offset().left - ink.width() / 2
        y = e.pageY - $target.offset().top - ink.height() / 2

        ink.css({ top: `${y}px`, left: `${x}px` }).addClass('animate')
      }
    }

    return {
      initialize
    }
  }())
}(jQuery))
