<template>
  <div
    v-on-clickaway="hideInput"
    class="c-service-menu__search"
    :class="{
      'is-opened': isFormVisible,
      'is-closing': isClosing
    }"
  >
    <form
      ref="form"
      class="c-service-menu__search-form u-flex u-flex-align-center"
      :action="model.url"
      :class="{
        'is-shown': isInputVisible
      }"
      @submit.prevent="redirectToSearchPage"
    >
      <template v-if="!model.autocompleteDisabled">
        <input
          ref="input"
          v-model="query"
          class="c-service-menu__search-field"
          type="search"
          :placeholder="model.inputPlaceholder"
          @click="openSearchField"
          @keydown.enter="onKeyboardEnter"
          @keydown.down="onKeyboardDown"
          @keydown.up="onKeyboardUp"
          @keydown.escape="hideInput"
          @keypress="resetKeyboardFlag"
        >
        <QuickSearchAutocomplete
          v-if="isFormVisible"
          :query="query"
          :offset="offset"
          :loading="isLoadingSuggestions"
          :suggestions="suggestions"
          :active-index="activeIndex"
        />
      </template>
      <template v-else>
        <input
          ref="input"
          v-model="query"
          class="c-service-menu__search-field"
          type="search"
          :placeholder="model.inputPlaceholder"
          @click="openSearchField"
        >
      </template>
    </form>
    <button
      ref="searchButton"
      :class="{
        'is-hidden': isInputVisible
      }"
      class="c-service-menu__button c-service-menu__search-trigger"
      :title="model.title"
      @click.prevent="openSearchField"
    >
      <Icon
        :icon="iconSearch"
        :size="24"
        stroke="black"
      />
    </button>
    <a
      v-if="isInputVisible"
      class="c-service-menu__search-button c-button--icon u-flex-align-center u-flex-justify-center"
      :href="model.url"
      :title="model.title"
      @click.prevent="redirectToSearchPage"
    >
      <Icon
        :icon="iconSearchBold"
        :size="16"
        fill="white"
      />
    </a>

    <CloseButton
      v-show="isInputVisible"
      class="c-service-menu__search-close"
      @click="hideInputOnClick"
    />
  </div>
</template>

<script>

import { mixin as clickaway } from 'vue-clickaway'


import iconSearch from '@ds/svg/icons/stroke/search.svg'
import iconSearchBold from '@ds/svg/icons/bold/search-alternate-bold.svg'
import api from '@/CVI/WebCore/lib/api'


import Icon from '@/CVI/WebCore/components/Icon.vue'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import decodeHtml from '@/CVI/WebCore/lib/decode-html'

export default {
  components: {
    Icon,
    CloseButton,
    QuickSearchAutocomplete: () => import('@/CVI/WebCore/components/Navigation/ServiceMenu/QuickSearchAutocomplete.vue')
  },
  mixins: [clickaway],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconSearchBold,
      iconSearch,
      isClosing: false,
      isFormVisible: false,
      isInputVisible: false,
      query: '',
      trackedOpenField: false,
      trackedCloseField: false,
      activeIndex: -1,
      offset: 0,
      suggestions: [],
      isLoadingSuggestions: false,
      cancelToken: null,
      header: document.getElementsByClassName('c-header')[0]
    }
  },
  watch: {
    async query() {
      if (this.model.autocompleteDisabled) {
        return
      }
      if (this.query.length >= 3 && !this.updateFromKeyboard) {
        const { query, cancelToken } = this
        if (cancelToken) {
          cancelToken.cancel()
        }

        this.cancelToken = api.createCancelToken()

        this.isLoadingSuggestions = true
        const {
          data: suggestions
        } = await api.search.autocomplete({ query, cancelToken: this.cancelToken })
        this.suggestions = suggestions
        this.activeIndex = -1
        this.isLoadingSuggestions = false
      } else if (this.query.length < 3) {
        this.suggestions = []
      }
    },
    suggestions() {
      if (this.suggestions.length) {
        this.header.classList.add('search-active')
      } else {
        this.header.classList.remove('search-active')
      }
    }
  },
  methods: {
    openSearchField() {
      this.isFormVisible = true
      this.isInputVisible = true
      if (this.$mq === 'desktop') {
        setTimeout(() => {
          this.$refs.input.focus()
        }, 200)
      }
      if (this.$mq !== 'desktop') {
        this.animateSearchIcon()
      }
      if (!this.trackedOpenField) {
        tracker.trackSimpleEvent({
          event: 'menuNavigationSearchOpen'
        })
        this.trackedOpenField = true
        this.trackedCloseField = false
      }
    },
    isRTL() {
      return document.documentElement.dir == 'rtl'
    },
    animateSearchIcon() {
      const { searchButton } = this.$refs
      const offset = searchButton.offsetLeft
      if (this.isRTL()) {
        const width = searchButton.offsetWidth
        searchButton.style.transform = `translateX(${window.innerWidth - offset - width}px)`
        this.$refs.input.style.transform = `translateX(${window.innerWidth - offset - width}px)`
        this.offset = window.innerWidth - offset - width
      } else {
        searchButton.style.transform = `translateX(-${offset}px)`
        this.$refs.input.style.transform = `translateX(-${offset}px)`
        this.offset = -offset
      }
    },
    redirectToSearchPage() {
      tracker.trackFreeTextSearch({
        event: 'menuNavigationFreetextSearch',
        freeTextSearch: this.query
      })
      window.location = this.model.url + (this.query ? `?query=${this.query}` : '')
    },
    hideInput() {
      this.query = ''
      this.$refs.searchButton.style.transform = ''
      this.$refs.input.style.transform = ''
      this.isInputVisible = false
      this.isClosing = true

      setTimeout(() => {
        this.suggestions = []
        this.isFormVisible = false
        this.isClosing = false
      }, 300)
    },
    hideInputOnClick() {
      this.hideInput()
      this.trackedOpenField = false

      if (!this.trackedCloseField) {
        tracker.trackSimpleEvent({
          event: 'menuNavigationSearchClose'
        })
        this.trackedCloseField = true
      }
    },
    onKeyboardEnter(event) {
      const { activeIndex, suggestions } = this
      if (activeIndex != -1 && suggestions[activeIndex]) {
        event.preventDefault()
        const { url } = suggestions[activeIndex]
        window.location.href = url
      }
    },
    onKeyboardUp() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1
      } else {
        this.activeIndex = this.suggestions.length - 1
      }
      this.updateInputField()
    },
    onKeyboardDown() {
      if (this.activeIndex < this.suggestions.length - 1) {
        this.activeIndex = this.activeIndex + 1
      } else {
        this.activeIndex = 0
      }
      this.updateInputField()
    },
    updateInputField() {
      this.updateFromKeyboard = true
      if (this.suggestions.length) {
        this.query = decodeHtml(this.suggestions[this.activeIndex].title)
      }
    },
    resetKeyboardFlag() {
      this.updateFromKeyboard = false
    }
  }
}
</script>
