import psl from 'psl';

(function ($) {
  $.fn.togglCookiePolicy = function () {
    const cookiePolicyAccepted = 'cookie-policy-accepted-value'

    /*
     * November 14th 2018:
     *
     * setCookie(string cookieName) will from now on always store cookies appending the cookie consent language to the cookieName string, if specified.
     * For example, when the code calls setCookie("TrackingCookieConsent") it will store the value "1" in "TrackingCookieConsentEN" if the cookie consent language is "en".
     *
     * getCookie(string cookieName) will return the value for cookieName plus cookie consent language if specified.
     */

    return this.each(function () {
      const cookieBox = $(this)
      // if the visitor has accepted a cookie policy then stop
      if (getCookie(cookiePolicyAccepted) === '1') {
        return
      }
      // otherwise show the box
      cookieBox.show()

      $(this).on('click', '#acceptButton', () => {
        // accept that the visitor has pressed the accept button on the consent form
        setCookie(cookiePolicyAccepted, '1')
        cookieBox.removeClass('active')

        // go through each cookie checkbox and set the cookie according to how the visitor checked the box.
        $('.js-cookies-settings').each((i, cookSet) => {
          const cookieValue = $(cookSet).prop('checked') ? 1 : 0
          setCookie(cookSet.name, cookieValue)
        })
        window.updateGtmBlacklist()
      })
    })

    function getCookieConsentLanguage() {
      const language = $('.cookie-policy').data('cookieconsentLanguage')
      return language ? language.toUpperCase() : ''
    }

    function setCookie(c_name, value) {
      // get the cookie consent language because the tracking cookies need be both domain and language independent
      const siteLang = getCookieConsentLanguage()

      // set the expire date of the cookie exdays from now.
      const exdate = new Date()
      exdate.setTime(+exdate + 1826 * 864e+5) // 1826 days = ~5 years.
      const c_value = `${escape(value)}; expires=${exdate.toUTCString()}`

      // the path should be / root otherwise it's only valid on the path we are on, plus below
      const root_path = '; path=/'

      // get the domain from HTML, otherwise get it from the url of the site.
      let domainAttr = $('.cookie-policy').data('cookieconsentDomain')
      let domainPart = ''
      if (domainAttr) {
        // if the editor wrote the domain with a leading . then remove it.
        if (domainAttr.charAt(0) === '.') {
          domainAttr = domainAttr.substr(1)
        }
        domainPart = `; domain=.${domainAttr}`
      } else {
        // pls - lib that contains base of possible domain names to parse real domain name without subdomains
        const domainData = psl.parse(window.location.host)
        domainPart = domainData.domain ? `; domain=.${domainData.domain}` : ''
      }

      // build and store the cookie.
      document.cookie = `${c_name + siteLang}=${c_value}${root_path}${domainPart}`
    }


    function getCookie(c_name) {
      // get the cookie consent language because the tracking cookies need be both domain and language independent
      const siteLang = getCookieConsentLanguage()
      // get the cookie value for cookie name plus site language
      let i; let x; let y; const
        ARRcookies = document.cookie.split(';')
      for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='))
        y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1)
        x = x.replace(/^\s+|\s+$/g, '')
        if (x === c_name + siteLang) {
          return unescape(y)
        }
      }

      return undefined
    }
  }
}(jQuery))
