(function (_) {
  window.vt = window.vt || {}

  /**
     * MediaQueryMonitor (Factory)
     * Executes provided callbacks in the 'callbackSpec' object based on numeric keys.
     * @param {object} callbackSpec - Callback specification object, must contain a series of 'min-width'
     *  numeric keys with corresponding callbacks to be executed when the viewport is greater than the
     *  given key (in pixels) and less than the next key in sorted order.
     * @return {object} - Factory instance interface including a destroy method to remove listeners.
    */
  vt.MediaQueryMonitor = function (callbackSpec) {
    if (!_.isPlainObject(callbackSpec)) {
      console.warn('callbackSpec parameter must be an object')
      return
    }

    const mediaQueryListeners = Object.keys(callbackSpec)
      .filter(filterIncompatibleKeys)
      .sort()
      .reverse()
      .reduce(createMediaQueryListeners(callbackSpec), [])

    return {
      destroy() {
        mediaQueryListeners.forEach((mediaQueryListener) => {
          listener.destroy()
        })
      }
    }
  }

  function filterIncompatibleKeys(key) {
    return key && !isNaN(key)
  }

  function createMediaQueryListeners(callbacks) {
    return function createMediaQueryListener(queryArr, currentKey) {
      const prevKey = _.last(queryArr)
      const prevWidth = prevKey && Number(_.get(prevKey, 'key'))
      const width = Number(currentKey)
      const mediaQuery = prevWidth
        ? `(min-width:${width}px) and (max-width:${prevWidth - 1}px)`
        : `(min-width:${width}px)`
      const mql = window.matchMedia(mediaQuery)
      const callback = callbacks[currentKey]

      mql.addListener(callback)

      callback(mql)

      return queryArr.concat({
        key: currentKey,
        query: mediaQuery,
        destroy: mql.removeListener.bind(mql, callback)
      })
    }
  }
}(_))
