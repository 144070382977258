import $ from 'jquery'
import Tracker from '@/CVI/WebCore/core-tracker'

window.vt = window.vt || {}

window.vt.EnhancedEcommerce = (function () {
  function getElementIndexByCheckingParents(rootDomElement) {
    const elementToTrackPosition = rootDomElement.parentNode
    if (elementToTrackPosition.nodeName.toLowerCase() == 'li') {
      return $(elementToTrackPosition).index()
    }
    return $(elementToTrackPosition.parentNode).index()
  }

  function initialize(rootDomElement) {
    const trackingData = $(rootDomElement).data('tracking')
    // for the sake of performance check only 2 parents
    if (trackingData.type == 'Product') {
      trackingData.position = getElementIndexByCheckingParents(rootDomElement) + 1

      Tracker.trackRecipeImpressions(rootDomElement, trackingData)
      $(rootDomElement).on('click', (event) => {
        Tracker.trackProductClick(event, trackingData, rootDomElement.href)
      })
    } else if (trackingData.type == 'DisplayItem') {
      Tracker.trackDisplayItemImpression(rootDomElement, trackingData)
      $(rootDomElement).on('click', (event) => {
        Tracker.trackDisplayItemImpressionClick(event, trackingData, rootDomElement.href)
      })
    }
  }

  return {
    initialize
  }
}())
