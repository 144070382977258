(function ($) {
  $.fn.addAttribute = function (options) {
    const settings = $.extend({
      addAttr: 'checked',
      addValue: true,
      type: 'input:checkbox',
      parent: '#country-options'
    }, options)

    return this.each(function () {
      const element = $(this)

      element.click(() => {
        if (element.is('input:checked')) {
          $(settings.parent).addClass('disabled')
          $(`${settings.parent} ${settings.type}`).each(function () {
            $(this).prop(settings.addAttr, settings.addValue)
            $(this).prop('disabled', true)
          })
        } else {
          $(settings.parent).removeClass('disabled')
          $(`${settings.parent} ${settings.type}`).each(function () {
            $(this).prop('disabled', false)
          })
        }
      })
    })
  }
}(jQuery))
