(function ($, _) {
  window.vt = window.vt || {}

  vt.AutoSubmit = (function () {
    // Constants
    const SELECTOR = 'select'

    function initialize(form) {
      const $form = $(form)

      const eventHandlers = addEvents($form)

      return {
        destroy: removeEvents.bind(null, $form, eventHandlers)
      }
    }

    function addEvents($form) {
      const formElementChangeHandler = _.partial(submitForm, $form)

      $form.on('change', SELECTOR, formElementChangeHandler)

      return {
        formElementChangeHandler
      }
    }

    function removeEvents($form, eventHandlers) {
      $form.off('change', SELECTOR, eventHandlers.formElementChangeHandler)
    }

    function submitForm($form, evt) {
      $form.submit()
    }

    return {
      initialize
    }
  }())
}(jQuery, _))
