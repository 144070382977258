
(function ($) {
  $.fn.searchInitScripts = function () {
    const container = $(this)

    /* ---- search.js ---- */
    $('.search-filter').find('.filter-title:not(:first)').not('.selected-filter-choice').generalCollapse({ collapsed: '.options' })
    $('.search-filter').find('.filter-title:first').not('.selected-filter-choice').generalCollapse({ collapsed: '.options', expandedOnInit: true })
    if (window.sessionStorage) {
      if ($('.search-result').length === 0) {
        $('form').clearSessionStorage('submit')
        $('.search-link').clearSessionStorage('click')
      } else {
        $('.search-filter').find('.filter-title').getExpandedFilterState()
        $('.search-filter').find('.filter-title').not('.selected-filter-choice').saveExpandedFilterState()
        $('.clear-search-filter').clearSessionStorage('click')
      }
    }

    $('.search-filter .expand-checkbox:first-of-type').prop('checked', true)

    $('.search-filter-toggle').click(function () {
      const
        $checkbox = $(this).find('> input[type=checkbox]')

      $checkbox.click()
      if ($checkbox.is(':checked')) {
        $(this).addClass('activated')
      } else {
        $(this).removeClass('activated')
      }
    })

    $('.search-filter-toggle > input[type=checkbox]').change(() => {
      $('.search-layout-aside').toggleClass('mobile-show')
    })

    // code for handling the resizing of the hero when submitting the search form
    const
      $hero = $('.hero-deck')
    const $searchForm = $('.hero-deck')
    if ($searchForm.length > 0) {
      $('.search-form form').one('submit', function (e) {
        const
          $hero = $(this).closest('.search-hero--expanded')

        e.preventDefault()
        e.stopPropagation()
        $hero.addClass('collapsed')
        $('.ac_results').remove()
        setTimeout(() => {
          $('.search-form form').submit()
        }, 100)
      })
    }
  }

  $(document).ready(function () {
    $(this).searchInitScripts()
  })
}(jQuery))
