(function ($) {
  window.vt = window.vt || {}

  vt.Speck = (function () {
    const ACTIVE_CLASS = 'is-active'
    const MOBILE_FULLSCREEN_CLASS = 'speck--mobile-fullscreen'
    let SHOW_MOBILE_FULLSCREEN = false

    const $body = $('body')
    let $blackout
    let $element
    let $contentContainer
    let $closeButton
    let $currentContent
    let currentOnCloseHandler

    // Functions
    function build() {
      $element = $('<div class="speck" />')
      $contentContainer = $('<div class="speck__content" />')

      $blackout = $('<div class="speck-blackout" />')
      $blackout.on('click', hide)

      $closeButton = $('<button type="button" class="speck__close-button">Close</button>')
      $closeButton.on('click', hide)

      $element.append([$closeButton, $contentContainer])

      $body.append($blackout)
      $body.append($element)
    }

    function show($content, onCloseHandler, showMobileFullscreen) {
      SHOW_MOBILE_FULLSCREEN = false

      if (!$element) {
        build()
      }

      $body.on('keydown', (evt) => {
        evt = evt || window.event
        if (evt.keyCode == 27) {
          hide()
        }
      })

      if (onCloseHandler) {
        if (typeof onCloseHandler === 'boolean') {
          SHOW_MOBILE_FULLSCREEN = onCloseHandler
        } else {
          currentOnCloseHandler = onCloseHandler
        }
      }

      if (showMobileFullscreen) {
        SHOW_MOBILE_FULLSCREEN = showMobileFullscreen
      }

      if ($currentContent) {
        $currentContent.remove()
      }

      $currentContent = $content
      $contentContainer.append($content)

      setTimeout(display, 250)
    }

    function display() {
      if (SHOW_MOBILE_FULLSCREEN === true) {
        $element.addClass(MOBILE_FULLSCREEN_CLASS)
      }

      $element.addClass(ACTIVE_CLASS)
      $blackout.addClass(ACTIVE_CLASS)

      const overflowCss = {
        overflow: 'hidden'
      }

      $body.css(overflowCss)

      $closeButton.focus()
    }

    function hide() {
      if (currentOnCloseHandler) {
        currentOnCloseHandler()
        currentOnCloseHandler = null
      }

      $element.removeClass(ACTIVE_CLASS)
      $blackout.removeClass(ACTIVE_CLASS)
      $element.removeClass(MOBILE_FULLSCREEN_CLASS)

      $body.off('keydown')
      $body.attr('style', '')
    }

    return {
      show,
      hide
    }
  }())
}(jQuery))
