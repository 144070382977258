import axios from 'axios'

const language = document.documentElement.lang
export default {
  shoppingList: {
    baseUrl: `/cvi/purchase/api/${language}`,
    get(recipePortions) {
      // TODO remove when switch to UID
      recipePortions.forEach((recipePortion) => {
        if (recipePortion.recipeId.indexOf('rdb:') > -1) {
          recipePortion.recipeId = recipePortion.recipeId.substring('rdb:'.length)
        }
      })
      return axios.post(`${this.baseUrl}/shoppinglist`, {
        recipePortions
      })
    }
  },
  recipeList: {
    baseUrl: `/cvi/purchase/api/${language}/recipelist`,
    get(recipePortions) {
      return axios.post(`${this.baseUrl}`, {
        recipePortions
      })
    }
  },
  vendors: {
    baseUrl: `/cvi/purchase/api/${language}/vendors`,
    get() {
      return axios.get(`${this.baseUrl}`)
    },
    canDeliver({ vendorId, postalCode }) {
      return axios.get(`${this.baseUrl}/candeliver`, {
        params: {
          vendorId, postalCode
        }
      })
    }
  },
  geoCoding: {
    baseUrl: `/cvi/purchase/api/${language}/geocoding`,
    getPostalCode({ lat, lng }) {
      return axios.get(`${this.baseUrl}`, {
        params: {
          lat,
          lng
        }
      })
    },
    getLocalityInfo(postalCode) {
      return axios.get(`${this.baseUrl}`, {
        params: {
          postalCode
        }
      })
    }
  },
  shoppingСart: {
    baseUrl: `/cvi/purchase/api/${language}/shoppingcart`,
    post({
      vendorName, recipePortions, draft, postalCode
    }) {
      // TODO remove when switch to UID
      recipePortions.forEach((recipePortion) => {
        if (recipePortion.recipeId.indexOf('rdb:') > -1) {
          recipePortion.recipeId = recipePortion.recipeId.substring('rdb:'.length)
        }
      })
      return axios.post(`${this.baseUrl}`, {
        vendorName,
        recipePortions,
        draft,
        postalCode
      })
    },
    postDraft(shoppingCartId) {
      return axios.post(`${this.baseUrl}/${shoppingCartId}`)
    },
    get(shoppingCartId) {
      return axios.get(`${this.baseUrl}/${shoppingCartId}`)
    },
    updateProductLine({
      shoppingCartId,
      lineId,
      newProductId,
      amount,
      selected
    }) {
      return axios.put(`${this.baseUrl}/${shoppingCartId}/product/${lineId}`, {
        productId: newProductId,
        amount,
        selected
      })
    }
  },
  mealPlan: {
    baseUrl: `/cvi/weeklymenu/api/${language}/mealplan/`,
    get(mealPlanCategoryId) {
      return axios.get(`${this.baseUrl}${mealPlanCategoryId}`)
    },
    getMealPlanner(mealPlanId, mealPlanCategoryId) {
      return axios.get(`${this.baseUrl}${mealPlanId}/category/${mealPlanCategoryId}`)
    }
  }
}
