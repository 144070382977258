(function ($) {
  window.vt = window.vt || {}

  vt.Shard = (function () {
    const ACTIVE_CLASS = 'is-active'

    const $body = $('body')
    let $blackout
    let $element
    let $contentContainer
    let $closeButton
    let $currentContent
    let currentOnCloseHandler

    // Functions
    function build() {
      $element = $('<div class="shard" />')
      $contentContainer = $('<div class="shard__content" />')

      $blackout = $('<div class="shard-blackout" />')
      $blackout.on('click', hide)

      $closeButton = $('<button type="button" class="shard__close-button">Close</button>')
      $closeButton.on('click', hide)

      $element.append([$closeButton, $contentContainer])

      $body.append($blackout)
      $body.append($element)
    }

    function show($content, onCloseHandler) {
      if (!$element) {
        build()
      }

      if (onCloseHandler) {
        currentOnCloseHandler = onCloseHandler
      }

      if ($currentContent) {
        $currentContent.detach()
      }

      $currentContent = $content
      $contentContainer.append($content)

      setTimeout(display, 250)
    }

    function display() {
      $element.addClass(ACTIVE_CLASS)
      $blackout.addClass(ACTIVE_CLASS)

      const overflowCss = {
        overflow: 'hidden'
      }

      $body.css(overflowCss)

      $closeButton.focus()
    }

    function hide() {
      if (currentOnCloseHandler) {
        currentOnCloseHandler()
        currentOnCloseHandler = null
      }

      $element.removeClass(ACTIVE_CLASS)
      $blackout.removeClass(ACTIVE_CLASS)

      $body.attr('style', '')
    }

    return {
      show,
      hide
    }
  }())
}(jQuery))
