(function ($, _) {
  window.vt = window.vt || {}

  vt.Geolocator = function () {
    const deferred = $.Deferred()
    const fallbackTimeout = setTimeout(_.partial(deferred.reject.bind(deferred), new Error('Geolocation operation timed out.')), 30000)
    const clearFallbackTimeout = clearTimeout.bind(null, fallbackTimeout)

    navigator.geolocation.getCurrentPosition(
      _.compose(
        clearFallbackTimeout,
        deferred.resolve.bind(deferred)
      ),
      _.compose(
        clearFallbackTimeout,
        deferred.reject.bind(deferred)
      )
    )

    return deferred.promise()
  }
}(jQuery, _))
