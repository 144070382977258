/* global console, jQuery, _, angular */

(function (_, angular) {
  if (angular) {
    const MixinsModule = angular.module('MixinsModule', [])

    // Factories
    MixinsModule.factory('Mixins', [
      'ControllerUtils',
      function Mixins(
        ControllerUtils
      ) {
        function formMixin(vm) {
          // Composed Methods
          const setFormData = _.partial(ControllerUtils.setViewModelValue, vm, 'formData')
          const setFormErrors = _.partial(ControllerUtils.setViewModelValue, vm, 'formErrors')
          const setFormFeedback = _.partial(ControllerUtils.setViewModelValue, vm, 'formFeedback')
          const setFormIsLoading = _.partial(ControllerUtils.setViewModelValue, vm, 'formIsLoading')
          const resetFormFeedback = setFormFeedback.bind(null, null)

          function setForm(response) {
            if (response === undefined) {
              return
            }
            setFormIsLoading(false)

            if (response.errors) {
              setFormErrors(response.errors)
              return
            }

            resetForm()
            setFormFeedback(response.message)
          }

          function resetForm() {
            setFormData({})
            setFormErrors(null)
          }

          function submitForm(req) {
            setFormFeedback(null)
            setFormIsLoading(true)

            return req.then(setForm)
              .catch((err) => {
                setForm(err)
                throw err
              })
          }

          return {
            // Properties
            formData: {},
            formErrors: null,
            formFeedback: null,
            formIsLoading: false,
            // Methods
            resetFormFeedback,
            submitForm
          }
        }

        return {
          formMixin
        }
      }])
  }
}(_, window.angular))
