var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{ref:"mainMenu",staticClass:"c-main-menu u-hidden-print",class:{
    'is-opened-overlay': _vm.isOverlayVisible,
    'has-no-animation': _vm.isAnimated,
    'is-opened': _vm.isMobileOpened,
    'is-closing': _vm.isClosing,
    'is-submenu-opened': _vm.isSubmenuOpened
  }},[_c('div',{staticClass:"c-main-menu__overlay",on:{"click":function($event){return _vm.hideOpenedSubmenu()}}}),_c('button',{staticClass:"c-main-menu__button",on:{"click":_vm.toggleButton}},[_vm._m(0)]),(_vm.isSubmenuOpened)?_c('strong',{staticClass:"c-main-menu__submenu-title u-flex u-flex-align-center u-flex-justify-center"},[_vm._v(" "+_vm._s(_vm.submenuTitle)+" ")]):_vm._e(),(_vm.isSubmenuOpened)?_c('button',{staticClass:"c-main-menu__close-button",on:{"click":_vm.backToMainMenu}},[_c('Icon',{staticClass:"c-main-menu__close-button-icon",attrs:{"icon":_vm.iconArrowLeft,"size":24,"stroke":"white"}})],1):_vm._e(),_c('ul',{ref:"nav",staticClass:"c-main-menu__list u-bare-list"},_vm._l((_vm.model.sections),function(section,index){return _c('li',{key:section.title,staticClass:"c-main-menu__item",class:{
        'is-active-item': index == _vm.openIndex || section.isSubmenuOpened
      },on:{"mouseenter":function($event){return _vm.onMenuItemHover(section, index)},"mouseleave":function($event){return _vm.onMenuItemLeave(section, index)}}},[_c('a',{staticClass:"c-main-menu__item-link",class:{
          'is-active': section.isActive
        },attrs:{"href":section.url},on:{"click":function($event){return _vm.showSubmenu($event, section, section.title)}}},[_vm._v(" "+_vm._s(section.title)+" "),(section.subsections.length)?_c('Icon',{staticClass:"c-main-menu__item-link-icon",attrs:{"icon":_vm.iconArrow,"size":16,"fill":"dark-gray"}}):_vm._e()],1),(section.subsections.length)?_c('SubMenu',{attrs:{"section":section,"is-active":_vm.isSubmenuOpened && section.isSubmenuOpened}}):_vm._e()],1)}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"c-main-menu__button-inner"},[_c('span',{staticClass:"c-main-menu__button-line"}),_c('span',{staticClass:"c-main-menu__button-line"}),_c('span',{staticClass:"c-main-menu__button-line"})])}]

export { render, staticRenderFns }