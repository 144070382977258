<template>
  <div
    class="c-loader"
    :class="cssClass"
  >
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    cssClass() {
      return [
        this.size ? `c-loader--${this.size}` : '',
        this.color ? `c-loader--${this.color}` : ''
      ]
    }
  }
}
</script>
