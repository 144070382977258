(function ($) {
  window.vt = window.vt || {}

  vt.BrandNavigation = (function () {
    function setupLevers(lever) {
      lever.addEventListener('change', () => {
        // if we are closing a menu then close all submenu's as well
        if (lever.checked === false) {
          const leverParent = lever.parentElement
          const subLevers = leverParent.querySelectorAll('.brand-navigation-lever')
          for (var index = 0; index < subLevers.length; index++) {
            subLevers[index].checked = false
          }
        } else {
          // if we are opening a menu then close all other menu's

          // we get all the levers
          const allLevers = document.querySelectorAll('.brand-navigation-lever')

          // we initialize an empty array to store the parent levers
          const parentLevers = []
          const $parents = $(lever).parents('li')
          // we loop through the parent LI's and find the first lever child.
          // if we have one we store it in the parentLevers array.
          $parents.each(function () {
            const parentLever = this.querySelector('.brand-navigation-lever')
            if (parentLever) {
              parentLevers.push(parentLever)
            }
          })

          // we loop through all the levers and if the lever we are looping over is not the current lever
          // or in our parentLevers array we remove the check mark.
          for (var index = 0; index < allLevers.length; index++) {
            if (allLevers[index] !== lever && !_.includes(parentLevers, allLevers[index])) {
              allLevers[index].checked = false
            }
          }
        }
      }, false)
    }

    function initialize(element) {
      // gets the selectred element and opens up the menu
      const selected = element.querySelector('.brand-nav_linkCssClass.selected')
      if (selected) {
        const $parents = $(selected).parents('li')
        $parents.each(function () {
          const lever = this.querySelector('.brand-navigation-lever')
          if (lever) {
            lever.checked = true
          }
        })
      }

      const levers = element.querySelectorAll('.brand-navigation-lever')
      for (let index = 0; index < levers.length; index++) {
        setupLevers(levers[index])
      }

      $('.brand-nav_linkCssClass').click(() => {
        document.body.classList.remove('nav-open')
        document.querySelector('.page-overlay').classList.remove('show')
      })

      $('.brand-navigation .burger-menu-button').click((e) => {
        e.preventDefault()
        document.body.classList.remove('nav-open')
        document.querySelector('.page-overlay').classList.remove('show')
      })
    }


    return {
      initialize
    }
  }())
}(jQuery))
