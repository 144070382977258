
(function ($) {
  $.fn.loadMoreProducts = function (elm) {
    const $parent = elm.parent()

    elm.click(() => {
      elm.data('page', elm.data('page') || 1)
      const productRequestData = { page: elm.data('page') }

      if ($parent.data('categories') !== '') {
        const categoriesAttr = $parent.data('categories')
        const categoryids = typeof categoriesAttr == 'number' ? [categoriesAttr] : categoriesAttr.split(',')
        productRequestData.categoryIds = categoryids
      }

      if ($parent.data('brands') !== '') {
        const brandsAttr = $parent.data('brands')
        const brandids = typeof brandsAttr == 'number' ? [brandsAttr] : brandsAttr.split(',')
        productRequestData.brandIds = brandids
      }

      productRequestData.culture = $parent.data('culture')
      productRequestData.order = $parent.data('order')

      $.ajax({
        type: 'POST',
        url: '/WebAppsProducts/ProductList/GetProducts',
        traditional: true,
        data: productRequestData,
        success(data) {
          const nextPage = parseInt(elm.data('page')) + 1
          elm.data('page', nextPage)

          renderProducts(data.Products, $parent)

          if (data.ProductsLeft <= 0) {
            $parent.find('.product-list-loadmore').hide()
          }
        },
        dataType: 'json'
      })
    })

    function renderProducts(data, container) {
      for (let i = 0; i < data.length; i++) {
        const elm = `${'<div class="product">'
            + '<a href="'}${data[i].Url}">`
              + '<figure>'
                + `<div class="product-image"><div><img src="${data[i].ImageUrl}" alt="${data[i].ImageAlt}"/></div></div>`
                + `<figcaption><div><strong>${data[i].Brand}</strong></div><h2>${data[i].Product}</h2></figcaption>`
              + '</figure>'
            + '</a></div>'

        container.find('.product-list-products').append(elm)
      }
    }
  }
}(jQuery))
