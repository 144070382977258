
(function ($) {
  $(document).ready(() => {
    let timeout = null
    const $header = $('#header')
    const $serviceNavHolder = $('.service-nav-holder')
    const $notificationBanner = $('.notification-banner')

    const getScrollDistance = (function () {
      let prevScrollY = 0

      return function (scrollY) {
        const scrollDistance = scrollY - prevScrollY
        prevScrollY = scrollY
        return scrollDistance
      }
    }())

    const getScrollDirection = function (scrollDistance) {
      // 1 for down, -1 for up
      return scrollDistance >= 0 ? 1 : -1
    }

    const setHeaderPos = function () {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      timeout = setTimeout(() => {
        const distance = getScrollDistance(window.scrollY)
        const direction = getScrollDirection(distance)

        if (distance === 0) {
          return
        }

        if (direction === 1
          && /* scrolling down */ !$header.hasClass('scrolls-down')
          && /* header menu is visible */ window.scrollY >= 60) {
          const notificationBannerIsVisible = $notificationBanner.hasClass('visible')
          const serviceNavHolderIsVisible = $serviceNavHolder.hasClass('open')

          if (notificationBannerIsVisible || serviceNavHolderIsVisible) {
            return
          }
          $header.addClass('scrolls-down')
        }

        if (direction === -1
          && /* scrolling up */ $header.hasClass('scrolls-down') /* header menu is hidden */) {
          $header.removeClass('scrolls-down')
        }
      }, 20)
    }

    $(document).on('scroll', setHeaderPos)
  })
}(jQuery))
