import initializer from './initializer'

window.angularModules = [];

(function ($, angular, angularModules) {
  $(document).ready(() => {
    initializer(document)

    // NOTE: Bootstrap collected angular modules gathered from previous script execution.
    if (angular && angularModules) {
      angular.bootstrap(document.querySelector('.angular-bootstrap'), angularModules)
    }
  })
}(jQuery, window.angular, window.angularModules))
