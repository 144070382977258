import imagesLoaded from 'imagesloaded'

imagesLoaded.makeJQueryPlugin($)

$('.collectionfeed-deck').each(function () {
  const $deck = $(this)

  const $holder = $deck.find('.arla_feed_elements')

  const $grid = $holder.masonry({
    itemSelector: '.content-block',
    percentPosition: true
  })

  const $btn = $deck.find('.arla_feed_load')

  const masonryFeedLoad = function () {
    const nextPage = $deck.data('page') + 1
    $deck.data('page', nextPage)

    $.ajax({
      type: 'GET',
      url: `/WebAppsCollectionFeed/api/feed/?contentId=${$deck.data('contentid')}&page=${$deck.data('page')}&pagesize=${$deck.data('size')}`,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      cache: false,
      success(data) {
        $.each(data.FeedElements, (key, value) => {
          const el = $(`<div class="content-block"><a class="arla_feed_element" href="${value.Link}"><div class="image-element"><img src="${value.Image}" /></div><div class="content-element"><div class="meta"><span class="category-tag">${value.ElementType}</span><time class="date">${value.Date}</time></div><h3>${value.Heading}</h3><p>${value.Text}</p></div></a></div>`)
          $grid.masonry().append(el).masonry('appended', el)
          const currentTotal = $deck.data('total')
          $deck.data('total', currentTotal + 1)
        })

        $grid.imagesLoaded().progress(() => {
          $grid.masonry('layout')
        })

        if (data.TotalCount <= $deck.data('total')) {
          $btn.hide()
        } else {
          $btn.show()
        }
      },
      error(jqXHR, textStatus, errorThrown) {
        console.log(`${textStatus.toUpperCase()}: ${errorThrown}`)
      }
    })
  }

  $btn.on('click', () => {
    masonryFeedLoad()
  })

  masonryFeedLoad()
})
