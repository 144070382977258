<template>
  <nav
    ref="mainMenu"
    class="c-main-menu u-hidden-print"
    :class="{
      'is-opened-overlay': isOverlayVisible,
      'has-no-animation': isAnimated,
      'is-opened': isMobileOpened,
      'is-closing': isClosing,
      'is-submenu-opened': isSubmenuOpened
    }"
  >
    <div
      class="c-main-menu__overlay"
      @click="hideOpenedSubmenu()"
    />
    <button
      class="c-main-menu__button"
      @click="toggleButton"
    >
      <span class="c-main-menu__button-inner">
        <span class="c-main-menu__button-line" />
        <span class="c-main-menu__button-line" />
        <span class="c-main-menu__button-line" />
      </span>
    </button>
    <strong
      v-if="isSubmenuOpened"
      class="c-main-menu__submenu-title u-flex u-flex-align-center u-flex-justify-center"
    >
      {{ submenuTitle }}
    </strong>
    <button
      v-if="isSubmenuOpened"
      class="c-main-menu__close-button"
      @click="backToMainMenu"
    >
      <Icon
        class="c-main-menu__close-button-icon"
        :icon="iconArrowLeft"
        :size="24"
        stroke="white"
      />
    </button>

    <ul
      ref="nav"
      class="c-main-menu__list u-bare-list"
    >
      <li
        v-for="(section, index) in model.sections"
        :key="section.title"
        class="c-main-menu__item"
        :class="{
          'is-active-item': index == openIndex || section.isSubmenuOpened
        }"
        @mouseenter="onMenuItemHover(section, index)"
        @mouseleave="onMenuItemLeave(section, index)"
      >
        <a
          class="c-main-menu__item-link"
          :href="section.url"
          :class="{
            'is-active': section.isActive
          }"
          @click="showSubmenu($event, section, section.title)"
        >
          {{ section.title }}
          <Icon
            v-if="section.subsections.length"
            class="c-main-menu__item-link-icon"
            :icon="iconArrow"
            :size="16"
            fill="dark-gray"
          />
        </a>
        <SubMenu
          v-if="section.subsections.length"
          :section="section"
          :is-active="isSubmenuOpened && section.isSubmenuOpened"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import iconArrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'
import iconArrowLeft from '@ds/svg/icons/stroke/arrow-left-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import SubMenu from '@/CVI/WebCore/components/Navigation/MainMenuSubmenu.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon,
    SubMenu
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      body: document.body,
      openIndex: -1,
      iconArrowLeft,
      iconArrow,
      timer: null,
      isAnimated: false,
      animatedTimer: null,
      isOverlayVisible: false,
      isMobileOpened: false,
      isClosing: false,
      submenuTitle: '',
      isSubmenuOpened: false
    }
  },
  watch: {
    isMobileOpened(isOpened) {
      if (isOpened) {
        this.body.classList.add('is-navigation-opened')
      } else {
        this.body.classList.remove('is-navigation-opened')
      }
    }
  },
  methods: {
    onMenuItemHover(section, index) {
      if (this.isMobile()) {
        return
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }

      if (section.subsections && section.subsections.length) {
        if (this.openIndex == -1) {
          this.delayTimer = setTimeout(() => {
            this.isOverlayVisible = true
            this.$refs.mainMenu.parentNode.classList.add('menu-opened')
            this.openIndex = index
          }, 200)
        } else {
          this.isOverlayVisible = true
          this.$refs.mainMenu.parentNode.classList.add('menu-opened')
          if (this.openIndex != -1) {
            this.isAnimated = true
          }
          this.openIndex = index
        }
      } else {
        this.openIndex = -1
        this.isOverlayVisible = false
        setTimeout(() => {
          this.$refs.mainMenu.parentNode.classList.remove('menu-opened')
        }, 400)
      }
    },
    onMenuItemLeave() {
      if (this.isMobile()) {
        return
      }
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }
      this.timer = setTimeout(() => {
        this.isAnimated = false
        this.openIndex = -1
        this.isOverlayVisible = false
        setTimeout(() => {
          this.$refs.mainMenu.parentNode.classList.remove('menu-opened')
        }, 400)
      }, 200)
    },
    toggleButton() {
      if (this.isMobileOpened) {
        this.isClosing = true
        this.isMobileOpened = false
        this.isSubmenuOpened = false
        setTimeout(() => {
          this.isClosing = false
          this.hideOpenedSubmenu()
        }, 300)
        tracker.trackSimpleEvent({ event: 'burgerMenuClose' })
      } else {
        this.isMobileOpened = true
        tracker.trackSimpleEvent({ event: 'burgerMenuOpen' })
      }
    },
    hideOpenedSubmenu() {
      const openedSubmenu = this.model.sections.find(section => section.isSubmenuOpened)
      if (openedSubmenu) {
        openedSubmenu.isSubmenuOpened = false
      }
    },
    isMobile() {
      const isTouch = !document.documentElement.classList.contains('no-touch')
      return (isTouch || (this.$mq !== 'desktop' && !isTouch))
    },
    showSubmenu(event, section, navigationItem) {
      if (section.subsections.length && this.isMobile()) {
        event.preventDefault()
        this.hideOpenedSubmenu()
        this.isSubmenuOpened = true
        section.isSubmenuOpened = true
        this.submenuTitle = section.title
      }
      tracker.trackMainNavClick({
        event: 'topMenuNavigationClick',
        navigationItem
      })
    },
    backToMainMenu() {
      this.isSubmenuOpened = false
      this.hideOpenedSubmenu()
    },
    documentClick(e) {
      if (this.isSubmenuOpened) {
        const el = this.$refs.nav
        const target = e.target
        if (el !== target && !el.contains(target)) {
          this.isSubmenuOpened = false
          this.hideOpenedSubmenu()
        }
      }
    }
  },
  created() {
    document.addEventListener('click', this.documentClick)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  }
}
</script>
