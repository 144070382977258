import api from '@/CVI/Purchase/lib/api'
import storeFactory from '@/CVI/WebCore/lib/store-export-factory'

const LS_CART_KEY = 'ShoppingVendorsModule.cart'
const LS_VENDOR_KEY = 'ShoppingVendorsModule.vendor'
const LS_VENDOR_BUTTON_CLICKED = 'ShoppingVendorsModule.vendorClicked'
const LS_ONE_TIME_VENDOR_KEY = 'ShoppingVendorsModule.oneTimeVendor'
const LS_POSTAL_CODE = 'ShoppingVendorsModule.postalCode'
const LS_LOCALITY = 'ShoppingVendorsModule.locality'
const LS_MEALPLAN_ENABLED = 'ShoppingVendorsModule.mealplanIsEnabled'
const LS_MEALPLAN = 'ShoppingVendorsModule.mealplan'
const LS_MEALPLAN_DRAGDROP_HINT = 'ShoppingVendorsModule.mealplanHintShown'

const store = {
  state: {
    selectedVendor: null,
    preselectedVendor: null,
    shoppingList: [],
    isShoppingListLoading: false,
    recipeList: [],
    isRecipeListLoading: false,
    isModalVendorSelectorVisible: false,
    vendors: [],
    isModalClearCartVisible: false,
    products: [],
    basicIngredients: [],
    isProductsLoading: false,
    swapProduct: null,
    matchedCount: null,
    canDeliver: false,
    exps: {
      highlightZipCode: false,
      highlightZipCodeText: '',
      highlightVendors: false,
      showVendorModalonModal: false
    },
    get cart() {
      const cart = JSON.parse(localStorage.getItem(LS_CART_KEY)) || []
      return cart.map((recipe) => {
        let { id } = recipe

        if (id) {
          const { portionCount } = recipe
          if (typeof id == 'number' || id.indexOf('rdb') == -1) {
            id = `rdb:${id}`
          }
          return {
            uid: id,
            portionCount
          }
        }
        return recipe
      })
    },
    set cart(value) {
      localStorage.setItem(LS_CART_KEY, JSON.stringify(value))
    },
    get postalCode() {
      return JSON.parse(localStorage.getItem(LS_POSTAL_CODE)) || ''
    },
    set postalCode(value) {
      localStorage.setItem(LS_POSTAL_CODE, JSON.stringify(value))
    },
    get locality() {
      return JSON.parse(localStorage.getItem(LS_LOCALITY)) || ''
    },
    set locality(value) {
      localStorage.setItem(LS_LOCALITY, JSON.stringify(value))
    },
    get isMealplanEnabled() {
      return JSON.parse(localStorage.getItem(LS_MEALPLAN_ENABLED)) || false
    },
    set isMealplanEnabled(value) {
      localStorage.setItem(LS_MEALPLAN_ENABLED, JSON.stringify(value))
    },
    get mealplan() {
      return JSON.parse(localStorage.getItem(LS_MEALPLAN)) || []
    },
    set mealplan(value) {
      localStorage.setItem(LS_MEALPLAN, JSON.stringify(value))
    },
    set mealplanDragDropHint(value) {
      localStorage.setItem(LS_MEALPLAN_DRAGDROP_HINT, JSON.stringify(value))
    },
    get mealplanDragDropHint() {
      return JSON.parse(localStorage.getItem(LS_MEALPLAN_DRAGDROP_HINT)) || null
    }
  },
  getters: {
    trackingRecipes(state) {
      return state.recipeList.map(({
        id, name, preparationTime, ingredients
      }) => ({
        price: 0,
        brand: 'Arla',
        category: window.gtmData ? window.gtmData.primaryCategory : 'Recipe',
        quantity: 1,
        id,
        name,
        dimension55: preparationTime,
        dimension56: ingredients.length
      }))
    },
    isDraftFlow(state) {
      return state.selectedVendor
        && state.selectedVendor.shoppingCartDraftEnabled
    },
    savedVendor() {
      return localStorage.getItem(LS_VENDOR_KEY) || localStorage.getItem(LS_ONE_TIME_VENDOR_KEY)
    },
    quickVendorButtonClicked() {
      return localStorage.getItem(LS_VENDOR_BUTTON_CLICKED)
    }
  },
  actions: {
    async fetchShoppingList({ state }, { recipePortions }) {
      state.isShoppingListLoading = true
      state.products = []
      if (recipePortions.length) {
        const {
          shoppingList: { ingredientGroups, basicIngredients },
          recipes
        } = (await api.shoppingList.get(recipePortions)).data

        state.shoppingList = ingredientGroups
        state.recipeList = recipes
        state.basicIngredients = basicIngredients
      } else {
        state.shoppingList = []
        state.recipeList = []
        state.basicIngredients = []
      }
      state.matchedCount = null
      state.isShoppingListLoading = false
    },
    async fetchVendors({ state }, { postalCode }) {
      const { data: vendors } = await api.vendors.get(postalCode)
      state.vendors = vendors
      state.matchedCount = null
    },
    async resetVendors({ state }) {
      state.selectedVendor = null
      const { data: vendors } = await api.vendors.get()
      state.vendors = vendors
    },
    async fetchProductList({ state }, {
      recipePortions, vendorName, shoppingCartId, draft, postalCode
    }) {
      state.isProductsLoading = true

      if (shoppingCartId) {
        const { data } = await api.shoppingСart.get(shoppingCartId)
        const {
          products,
          shoppingList: { ingredientGroups, basicIngredients },
          recipes,
          matchedCount
        } = data
        state.basicIngredients = basicIngredients
        state.products = products
        state.shoppingList = ingredientGroups
        state.recipeList = recipes
        state.matchedCount = matchedCount
      } else {
        const { data } = await api.shoppingСart.post({
          recipePortions,
          vendorName,
          draft,
          postalCode
        })
        const {
          products,
          shoppingList: { ingredientGroups, basicIngredients },
          recipes,
          id,
          matchedCount
        } = data
        state.basicIngredients = basicIngredients
        state.products = products
        state.shoppingList = ingredientGroups
        state.recipeList = recipes
        state.selectedVendor.shoppingCartId = id
        state.matchedCount = matchedCount
      }
      state.isProductsLoading = false
    },
    async updateProduct({ state }, {
      lineId,
      amount,
      newProductId,
      selected
    }) {
      const { shoppingCartId } = state.selectedVendor

      const { data: product } = await api.shoppingСart.updateProductLine({
        shoppingCartId,
        lineId,
        amount,
        newProductId,
        selected
      })

      const existingProductIndex = state.products.findIndex(({ lineId: itemLineId }) => itemLineId == lineId)
      state.products.splice(existingProductIndex, 1, product)
    },
    showSwapProduct({ state }, { product, ingredient }) {
      state.swapProduct = { product, ingredient }
    },
    hideSwapProduct({ state }) {
      state.swapProduct = null
    },
    saveVendor({ state }, { vendor, id }) {
      state.selectedVendor = vendor
      localStorage.setItem(LS_VENDOR_KEY, id)
    },
    saveQuickVendorButtonClick() {
      localStorage.setItem(LS_VENDOR_BUTTON_CLICKED, 1)
    },
    removeQuickVendorButtonClick() {
      localStorage.removeItem(LS_VENDOR_BUTTON_CLICKED)
    },
    clearVendor() {
      localStorage.removeItem(LS_VENDOR_KEY, null)
    },
    saveOneTimeVendor({ state }, { vendor, id }) {
      state.selectedVendor = vendor
      localStorage.setItem(LS_ONE_TIME_VENDOR_KEY, id)
    },
    clearOneTimeVendor() {
      localStorage.removeItem(LS_ONE_TIME_VENDOR_KEY)
    },
    addRecipesToCart({ state }, recipes) {
      const { cart } = state

      recipes.forEach(({ uid, portionCount }) => {
        const item = cart.find(x => x.uid == uid)
        if (item) {
          item.portionCount = portionCount
        } else {
          let newUid = uid
          if (typeof uid == 'number' || uid.indexOf('rdb') == -1) {
            newUid = `rdb:${uid}`
          }
          const existingRecipe = cart.find(x => x.uid == newUid)
          if (existingRecipe) {
            existingRecipe.portionCount = portionCount
          } else {
            cart.push({
              uid: newUid,
              portionCount
            })
          }
        }
      })

      state.cart = cart
    },
    removeFromCart({ state }, { recipeUid: uid }) {
      const { cart } = state
      const item = cart.find(x => (x.uid == uid) || (x.uid == `rdb:${uid}`) || (`rdb:${x.uid}` == uid))

      if (item) {
        cart.splice(cart.indexOf(item), 1)
      }
      state.cart = cart
    },
    replaceRecipe({ state }, { oldRecipeUid, recipe }) {
      const { cart } = state
      const itemIndex = cart.findIndex(x => (x.uid == oldRecipeUid) || (x.uid == `rdb:${oldRecipeUid}`) || (`rdb:${x.uid}` == oldRecipeUid))
      cart[itemIndex] = recipe
      state.cart = cart
    },
    clearCart({ state }) {
      state.cart = []
    },
    setCanDeliver({ state }, { canDeliver }) {
      state.canDeliver = canDeliver
    },
    setUserLocality({ state }, { locality }) {
      state.locality = locality
    },
    setMealPlanState({ state }, { value }) {
      state.isMealplanEnabled = value
    },
    hideMealPlanDragDropHint({ state }) {
      state.mealplanDragDropHint = true
    },
    saveMealplan({ state }, { mealplan }) {
      const mealplanRecipes = []
      mealplan.forEach(({ day, recipes }) => {
        if (recipes.length) {
          recipes.forEach(({ uid }) => {
            mealplanRecipes.push({
              day,
              uid
            })
          })
        }
      })
      state.mealplan = mealplanRecipes
    },
    addMealplan({ state }, { mealplan }) {
      const mealplanToSave = [...state.mealplan, ...mealplan]
      const mealplanWithoutDuplicates = []

      mealplanToSave.forEach((item) => {
        if (!mealplanWithoutDuplicates
          .filter(({ uid }) => item.uid == uid).length
        ) {
          mealplanWithoutDuplicates.push(item)
        }
      })
      state.mealplan = mealplanWithoutDuplicates
    }
  }
}

export default storeFactory.create(store)
