(function ($) {
  // On enter always submits the closest button
  $.fn.keySubmit = function () {
    $(this).on('keydown', 'input, select', function (e) { // retrieve all inputs
      // $(this)
      // .keydown(function (e) { // bind keydown on all inputs
      if (e.keyCode == 13) { // enter was pressed
        const selector = ($(this).closest('fieldset').length > 0) ? 'fieldset' : 'div:not(div.select)'
        $(this).closest(selector).find(':submit').eq(0)
          .focus()
      }
    })
  }

  // For select-element that needs to be submitted on change. I.e. Hits on serach-result
  $.fn.submitOnChange = function () {
    $(this).on('change', function () {
      const selector = ($(this).closest('fieldset').length > 0) ? 'fieldset' : 'div:not(div.select)'
      $(this).closest(selector).find(':submit').eq(0)
        .click()
    })
    return this
  }

  // posting to a proxy and display the result or an error
  $.fn.ajaxProxy = function (settings) {
    const scope = $(this)
    const o = $.extend({
      url: '',
      getVars: false,
      selector: 'button',
      action: 'click',
      replacement: 'fieldset',
      callbackArguments: '',
      displayCallback: false,
      errorClass: 'server-error'
    }, settings)

    return $(this).each(() => {
      $(scope).on(o.action, o.selector, function (event) {
        event.preventDefault()
        // If we got data to post and it's not already populated with ajaxVar
        if (o.postData) {
          if (!o.postData.isAjaxCallback) {
            const tempData = {}
            tempData.isAjaxCallback = true
            $.extend(o.postData, tempData)
          }
        } else {
          const serial = scope.find('input').serialize()
          o.postData = `${serial}&isAjaxCallback=true`
        }

        // if get variables
        if (o.getVars) {
          // if href has parameters or if we find an selected option with parameters
          if ($(this).attr('href') && $(this).attr('href').indexOf('?') > 0) {
            o.url += $(this).attr('href').match(/\?(.*)/)[0]
          } else if ($(this).find('option:selected').attr('value') && $(this).find('option:selected').attr('value').indexOf('?') > 0) {
            o.url += $(this).find('option:selected').attr('value').match(/\?(.*)/)[0]
          }
        }

        $.ajax({
          type: 'POST',
          url: o.url,
          data: o.postData,
          success(data) {
            const elToAdd = $(data).filter(o.replacement).add($(data).find(o.replacement))
            scope.html(elToAdd.html())

            if (o.displayCallback) o.displayCallback.call(scope, o.callbackArguments)
          },
          error(e) {
            $(`<p class="${o.errorClass}">${arlaTranslations.ajaxErrorMessage}</p>`).insertAfter(scope)
          },
          complete() {
            o.postData = null
          }
        })

        return false
      })
    })
  }
}(jQuery))
