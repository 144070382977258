// / <reference path="cookiepolicy-init.js" />
(function ($) {
  $.fn.cookiePolicyInitScripts = function () {
    // Bind the toggl functionality
    $('section.cookie-policy').togglCookiePolicy()
    setTimeout(() => {
      $('section.cookie-policy').addClass('active')
    }, 500)
  }

  $(document).ready($(this).cookiePolicyInitScripts)
}(jQuery))
