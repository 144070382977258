(function ($, _, Modernizr, matchMedia, mediaQueryMonitor) {
  window.vt = window.vt || {}

  vt.ResponsiveBackground = (function () {
    function initialize(element) {
      const backgroundAttrs = _.get(element, 'dataset.responsiveBackgrounds')
      const backgroundSpec = JSON.parse(backgroundAttrs)
      const callbackSpec = Object.keys(backgroundSpec).reduce((callbacks, breakpointKey) => {
        const obj = {}
        obj[breakpointKey] = _.partial(replaceBackground, element, backgroundSpec[breakpointKey])

        return _.merge({}, callbacks, obj)
      }, {})

      return mediaQueryMonitor(callbackSpec)
    }

    function replaceBackground(element, background, mql) {
      if (mql.matches) {
        element.style.backgroundImage = _.isArray(background)
          ? background.map(getBackgroundImageStyle).join(',')
          : getBackgroundImageStyle(background)
      }
    }

    function getBackgroundImageStyle(backgroundImg) {
      return `url(${backgroundImg})`
    }

    return {
      initialize
    }
  }())
}(jQuery, _, Modernizr, window.matchMedia, vt.MediaQueryMonitor))
