import iFrameResize from './Vendors/iframeResizer'

(function ($, _) {
  window.vt = window.vt || {}

  vt.IframeBlock = (function () {
    function initialize(el) {
      const iframeId = el.dataset.jsIframeId
      const iframeSrc = el.dataset.jsIframeSrc

      window.addEventListener('load', () => {
        if (window.google_tag_manager) {
          window.dataLayer.push({
            event: 'Tracked Iframe Ready',
            trackedIframeLoaded: true,
            trackedIframeId: iframeId,
            trackedIframeSource: iframeSrc,
            eventCallback() {
              setupIframe(iframeId, iframeSrc)
            }
          })
        } else {
          setupIframe(iframeId, iframeSrc)
        }
      }, false)
    }

    function setupIframe(iframeId, iframeSrc) {
      // console.log("Setting up %s using the url %s", iframeId, iframeSrc);
      const iframeToSetup = document.querySelector(`#${iframeId}`)

      if (iframeToSetup.src !== '') {
        // console.log("Iframe %s is already set up. Stopping.", iframeId);
        return false
      }

      iframeToSetup.src = iframeSrc
      iframeToSetup.onload = function () {
        iFrameResize({
          log: false,
          heightCalculationMethod: 'lowestElement',
          minHeight: 1000
        }, iframeToSetup)
      }
    }

    return {
      initialize,
      setupIframe
    }
  }())
}(jQuery, _))
