import store from '@/CVI/WebCore/store'

function handleLogin() {
  const { hash } = window.location

  if (!hash.startsWith('#login')) {
    return
  }

  const returnUrlMatch = /returnUrl=([^&]+)/i.exec(hash)
  const returnUrl = returnUrlMatch && returnUrlMatch[1]

  if (returnUrl) {
    store.state.loginReturnUrl = returnUrl
  }

  store.state.isLoginPopupVisible = true
}

window.addEventListener('hashchange', handleLogin)

handleLogin()
