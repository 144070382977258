import Vue from 'vue'

// wraps root Vue components to pass "class" and "style" attributes to root element automatically
export default Vue.extend({
  name: 'Proxy',
  functional: true,
  props: {
    component: {
      type: Object,
      required: true
    },
    props: {
      type: Object,
      required: true
    }
  },
  render(createElement) {
    return createElement(this.component, {
      class: this.props.class,
      style: this.props.style,
      props: this.props
    })
  }
})
