(function ($) {
  /*
     * Used as some form of generic modal but seems to only be used for foodboxes
     * Not sure how it works.
     */
  let timeOutId
  $('body').on('click', '.js-modal-activator', onModalActivation)
  $('body').on('modal:activate', onModalActivation)
  $('.js-generic-modal__overlay').on('click', closeModal)

  function onModalActivation(e, options) {
    let
      eventName
    let $configContainer
    let $parentModal
    let useParentContainer
    let $modal

    // variable initialization start
    if (options && options.customEvent) {
      useParentContainer = false
      eventName = options.customEvent
    } else {
      $parentModal = $(this).closest('[data-walkthrough-container="true"]')
      useParentContainer = $parentModal.length > 0
      eventName = $(this).data().customEvent
    }
    $modal = $('.generic-modal')
    // variable initialization end

    if (e) { e.preventDefault() }

    if (timeOutId) {
      clearTimeout(timeOutId)
      timeOutId = undefined
    }

    if ($modal.hasClass('hidden') && !useParentContainer) {
      $modal.removeClass('hidden')
    }

    if (!eventName) {
      $configContainer = $(this).closest('[data-custom-event]')
      eventName = $configContainer.length > 0 ? $configContainer.data().customEvent : null
    }
    if (eventName) {
      $('body').trigger(eventName, {
        modalDomElement: useParentContainer ? $parentModal[0] : $modal.find('.generic-modal__content-container')[0]
      })
    }
    if (!useParentContainer) {
      setTimeout(() => {
        $modal.addClass('activated')
      }, 100)
    }
  }

  function closeModal(e) {
    let
      $modal = $(this).closest('.generic-modal')

    if ($modal.length === 0) {
      $modal = $('.generic-modal.activated')
    }
    if ($modal.hasClass('activated')) {
      $modal.removeClass('activated')
      timeOutId = setTimeout(() => {
        $modal.addClass('hidden')
      }, 1000)
    }
  }

  $('.generic-modal__close-button').on('click', closeModal)
  $('body').on('generic-modal:close', closeModal)
}(jQuery))
