import Vue from 'vue'

const mountComponent = ({ component, props, elementToAppend = document.createElement('div') }) => {
  if (component) {
    document.body.appendChild(elementToAppend)
    new Vue({
      functional: true,
      render(createElement) {
        return createElement(component, {
          props
        })
      }
    }).$mount(elementToAppend)
  }
}

export default mountComponent
