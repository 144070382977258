// Create a global tracker object for handling tracking
import Cookies from 'js-cookie'

const gtmData = window.gtmData || {}
const gtmSettings = window.gtmSettings || {}
const isEcommerceEnabled = gtmSettings.enableEnhancedEcommerce

const Tracker = {
  track(obj) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
  },
  trackRecipeImpressions(domElement, {
    name, cookingTime, ingredientCount, arlaProductCount, id, mealType, position
  }) {
    if (!isEcommerceEnabled) {
      return
    }
    const impressionObj = {
      event: 'Impressions',
      eventCategory: 'Ecommerce',
      eventAction: 'impression',
      ecommerce: {
        currencyCode: gtmSettings.currency,
        impressions: [{
          brand: 'Arla',
          price: '0',
          category: 'Recipe',
          list: gtmData.pageTitle,
          dimension55: cookingTime,
          dimension56: ingredientCount,
          dimension57: arlaProductCount,
          name,
          variant: mealType,
          position,
          id
        }]
      }
    }


    const observeOptions = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.9
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.track(impressionObj)
          observer.unobserve(entry.target)
        }
      })
    },
    observeOptions)
    observer.observe(domElement)
  },
  trackProductClick(event, {
    name, cookingTime, ingredientCount, arlaProductCount, id, mealType, position
  }, url) {
    if (!isEcommerceEnabled) {
      return
    }
    event.preventDefault()

    const timer = setTimeout(() => {
      document.location = url
    }, 1000)

    const clickObject = {
      event: 'productClick',
      eventCategory: 'Ecommerce',
      eventAction: 'click',
      ecommerce: {
        click: {
          actionField: { list: gtmData.pageTitle },
          products: [{
            name,
            id,
            price: 0,
            brand: 'Arla',
            category: 'Recipe',
            variant: mealType,
            position,
            dimension55: cookingTime,
            dimension56: ingredientCount,
            dimension57: arlaProductCount
          }]
        }
      },
      eventCallback() {
        if (url) {
          if (timer) {
            clearTimeout(timer)
          }
          document.location = url
        }
      }
    }

    this.track(clickObject)
  },
  trackSelectVendor({
    vendor,
    vendorRank,
    products
  }) {
    if (!isEcommerceEnabled) {
      return
    }
    const checkoutObj = {
      event: 'checkout',
      eventCategory: 'Ecommerce',
      eventAction: 'Select retailer',
      ecommerce: {
        checkout: {
          actionField: {
            step: 2,
            option: vendor,
            dimension53: vendorRank
          },
          products
        }
      }
    }

    this.track(checkoutObj)
  },

  trackAddToCart({
    name, cookingTime, ingredientCount, arlaProductCount, id, mealType, eventAction = 'Add to basket'
  }) {
    if (!isEcommerceEnabled) {
      return
    }
    const checkoutObj = {
      event: 'checkout',
      eventCategory: 'Ecommerce',
      eventAction,
      ecommerce: {
        checkout: {
          actionField: {
            step: 1
          },
          products: [{
            dimension55: cookingTime,
            dimension56: ingredientCount,
            dimension57: arlaProductCount,
            price: '0',
            brand: 'Arla',
            category: 'Opskrifter',
            name,
            id,
            variant: mealType,
            quantity: 1
          }]
        }
      }
    }

    this.track(checkoutObj)
  },

  trackCheckoutOption({
    vendor,
    vendorRank,
    products
  }) {
    if (!isEcommerceEnabled) {
      return
    }
    const checkoutObj = {
      event: 'checkout',
      eventCategory: 'Ecommerce',
      eventAction: 'Go to retailer',
      ecommerce: {
        checkout: {
          actionField: {
            step: 3,
            option: vendor,
            dimension53: vendorRank
          },
          products
        }
      }
    }

    this.track(checkoutObj)
  },

  trackActionBar({ event, position }) {
    const clickObject = {
      event,
      position
    }
    this.track(clickObject)
  },

  trackShareLinks({ event, position, network }) {
    const clickObject = {
      event,
      position,
      network
    }
    this.track(clickObject)
  },

  trackPortions({ event, position }) {
    const clickObject = {
      event,
      position
    }
    this.track(clickObject)
  },

  trackRecipeRating({ event, recipeRating }) {
    const clickObject = {
      event,
      recipeRating
    }
    this.track(clickObject)
  },

  trackSimpleEvent({ event }) {
    const clickObject = {
      event
    }
    this.track(clickObject)
  },

  trackRecipeProduct({ event, recipeProductName }) {
    const clickObject = {
      event,
      recipeProductName
    }
    this.track(clickObject)
  },

  trackMainNavClick({ event, navigationItem }) {
    const clickObject = {
      event,
      navigationItem
    }
    this.track(clickObject)
  },

  trackDropdownNavClick({ event, navigationItem }) {
    const clickObject = {
      event,
      navigationItem
    }
    this.track(clickObject)
  },

  trackLanguageSelection({ event, language }) {
    const clickObject = {
      event,
      language
    }
    this.track(clickObject)
  },

  trackFreeTextSearch({ event, freeTextSearch }) {
    const clickObject = {
      event,
      freeTextSearch
    }
    this.track(clickObject)
  },

  trackRecipeStep({ event, recipeStepNumber }) {
    const clickObject = {
      event,
      recipeStepNumber
    }
    this.track(clickObject)
  },

  trackIngredientMark({ event, ingredientName }) {
    const clickObject = {
      event,
      ingredientName
    }
    this.track(clickObject)
  },

  trackQuickSearchSuggestClick({ event, dropdownSearchClick }) {
    const clickObject = {
      event,
      dropdownSearchClick
    }
    this.track(clickObject)
  },

  trackRemoveItemShoppingCart({ event, deleteSavedRecipeDropdown }) {
    const clickObject = {
      event,
      deleteSavedRecipeDropdown
    }
    this.track(clickObject)
  },

  trackClickItemShoppingCart({ event, shoppingCartMenuRecipe }) {
    const clickObject = {
      event,
      shoppingCartMenuRecipe
    }
    this.track(clickObject)
  },

  trackLoginSelect({ loginAreaSelect }) {
    const clickObject = {
      event: 'menuNavigationLoginSelect',
      loginAreaSelect
    }
    this.track(clickObject)
  },

  trackLoginAreaLinks({ event, loginAreaLink }) {
    const clickObject = {
      event,
      loginAreaLink
    }
    this.track(clickObject)
  },

  trackSavedRecipesClick({ event, savedRecipeClickTitle }) {
    const clickObject = {
      event,
      savedRecipeClickTitle
    }
    this.track(clickObject)
  },

  trackLoggedInMenuLinks({ event, loggedInMenuItemClick }) {
    const clickObject = {
      event,
      loggedInMenuItemClick
    }
    this.track(clickObject)
  },

  trackYearDropdownClick({ event, dropdownSelectYear }) {
    const clickObject = {
      event,
      dropdownSelectYear
    }
    this.track(clickObject)
  },

  trackYearContentTypeClick({ event, dropdownSelectContent }) {
    const clickObject = {
      event,
      dropdownSelectContent
    }
    this.track(clickObject)
  },

  trackSocialNewsroomSharing({ event, shareType, shareContentTitle }) {
    const clickObject = {
      event,
      shareType,
      shareContentTitle
    }
    this.track(clickObject)
  },

  trackNewsroomContentSelect({ event, contentType, contentTitle }) {
    const clickObject = {
      event,
      contentType,
      contentTitle
    }
    this.track(clickObject)
  },

  trackNewsroomRelatedArticleClick({ event, relatedArticleTitle }) {
    const clickObject = {
      event,
      relatedArticleTitle
    }
    this.track(clickObject)
  },

  trackNewsroomSubMenuClick({ event, subMenuButton }) {
    const clickObject = {
      event,
      subMenuButton
    }
    this.track(clickObject)
  },

  trackNewsroomBreadcrumbsClick({ event, breacrumbUrl }) {
    const clickObject = {
      event,
      breacrumbUrl
    }
    this.track(clickObject)
  },

  trackAdimoButtonClick({ event, touchpointId }) {
    const clickObject = {
      event,
      touchpointId
    }
    this.track(clickObject)
  },

  trackGridPageItemClick() {
    const trackingItems = document.querySelectorAll('[data-grid-page-item]')

    trackingItems.forEach((item) => {
      item.addEventListener('click', () => {
        this.track({
          event: 'pageGridClick',
          pageGridTitle: item.dataset.trackingKicker + ' | ' + item.dataset.trackingTitle
        })
      })
    })
  },

  trackGridProductItemClick() {
    const trackingItems = document.querySelectorAll('[data-grid-product-item]')

    trackingItems.forEach((item) => {
      item.addEventListener('click', () => {
        this.track({
          event: 'productGridClick',
          productGridName: item.dataset.trackingKicker + ' | ' + item.dataset.trackingTitle
        })
      })
    })
  },

  trackAccordionItemClick({ event, faqName }) {
    const clickObject = {
      event,
      faqName
    }
    this.track(clickObject)
  },

  trackTabItemClick({ event, tabName }) {
    const clickObject = {
      event,
      tabName
    }
    this.track(clickObject)
  },

  trackSimpleEventGlobal() {
    const trackingItems = document.querySelectorAll('[data-tracking-event]')

    trackingItems.forEach((item) => {
      const { trackingEvent, trackingTitle } = item.dataset
      if (item.hasAttribute('data-tracking-title')) {
        item.addEventListener('click', () => {
          this.track({
            event: trackingEvent,
            relatedRecipeTitle: trackingTitle
          })
        })
      } else {
        item.addEventListener('click', () => {
          this.track({ event: trackingEvent })
        })
      }
    })
  },

  milkyWhiteTracking() {
    const userSettings = document.getElementsByClassName('my-pages-header__action--settings')[0]
    const userLogOut = document.getElementsByClassName('my-pages-header__action--logout')[0]
    const userUnsubscribeAll = document.getElementsByClassName('js-unsubscribe-all-consents')[0]
    const userUnsubscribe = document.getElementsByClassName('js-unsubscribe-consent')[0]
    const deleteUser = document.getElementsByClassName('js-delete-user')[0]
    const userCockpit = document.querySelectorAll('[data-vue="CustomerWebUserCockpitLoader"]')
    if (userSettings) {
      userSettings.addEventListener('click', () => {
        this.track({
          event: 'milkyWhiteSettings'
        })
      })
    }
    if (userLogOut) {
      userLogOut.addEventListener('click', () => {
        this.track({
          event: 'milkyWhiteLogout'
        })
      })
    }
    if (userUnsubscribeAll) {
      userUnsubscribeAll.addEventListener('click', () => {
        this.track({
          event: 'milkyWhiteUnsubscribeAll'
        })
      })
    }
    if (userUnsubscribe) {
      userUnsubscribe.addEventListener('click', () => {
        this.track({
          event: 'milkyWhiteUnsubscribe',
          subscriptionName: 'ugens madplan fra karolines køkken ®'
        })
      })
    }
    if (deleteUser) {
      deleteUser.addEventListener('click', () => {
        this.track({
          event: 'milkyWhiteDeleteUser'
        })
      })
    }
    if (userCockpit.length) {
      const ucParent = setInterval(() => {
        if (document.getElementsByClassName('uc-root')[0]) {
          clearInterval(ucParent)
          const ucAccordion = setInterval(() => {
            if (document.getElementsByClassName('uc-accordion')[0]) {
              const ucAccordionToggler = document.getElementsByClassName('uc-accordion__toggle')
              clearInterval(ucAccordion)
              ucAccordionToggler.forEach((item) => {
                item.addEventListener('click', () => {
                  this.track({
                    event: 'milkyWhiteConsentToggle',
                    consentName: item.getElementsByClassName('uc-accordion__item-header')[0].children[0].innerText
                  })
                })
              })
            }
          }, 500)
        }
      }, 500)
    }
    if (Cookies.get('user_signup')) {
      this.track({
        event: 'milkyWhiteSignupComplete'
      })
      Cookies.remove('user_signup')
    }
  },

  getElementIndexByCheckingParents(rootDomElement) {
    const elementToTrackPosition = rootDomElement.parentNode
    if (elementToTrackPosition.nodeName.toLowerCase() == 'li') {
      return Array.prototype.indexOf
        .call(
          elementToTrackPosition.parentNode.childNodes, elementToTrackPosition
        )
    }
    return Array.prototype.indexOf
      .call(
        elementToTrackPosition.parentNode.parentNode.childNodes, elementToTrackPosition.parentNode
      )
  },

  trackDisplayItemImpression(elem, trackingData) {
    if (!isEcommerceEnabled) {
      return
    }
    const observeOptions = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.50
    }
    const {
      id, name, position, variant, category, creative
    } = trackingData

    const isHidden = (el) => {
      return (el.offsetParent === null)
    }

    const observer = new IntersectionObserver((entries) => {
      let timer = null

      entries.forEach((entry) => {
        if (entry.isIntersecting && !isHidden(entry.target)) {
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.track({
              event: 'eec.promotionView',
              ecommerce: {
                promoView: {
                  promotions: [{
                    id,
                    name,
                    position,
                    category,
                    creative,
                    variant
                  }]
                }
              }
            })
          }, 1000)
          observer.unobserve(entry.target)
        } else {
          clearTimeout(timer)
        }
      })
    }, observeOptions)
    observer.observe(elem)
  },

  trackDisplayItemImpressionClick(event, trackingData, url) {
    const {
      id, name, position, variant, category, creative
    } = trackingData
    event.preventDefault()

    const timer = setTimeout(() => {
      document.location = url
    }, 1000)
    this.track({
      event: 'eec.promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [{
            id,
            name,
            position,
            category,
            creative,
            variant
          }]
        }
      },
      eventCallback() {
        if (url) {
          if (timer) {
            clearTimeout(timer)
          }
          document.location = url
        }
      }
    })
  },

  enhancedEcommerceTrackerInitialize(rootDomElement) {
    if (!isEcommerceEnabled) {
      return
    }
    const trackingData = JSON.parse(rootDomElement.dataset.tracking)

    if (trackingData.type == 'Product') {
      trackingData.position = this.getElementIndexByCheckingParents(rootDomElement)
      this.trackRecipeImpressions(rootDomElement, trackingData)
      rootDomElement.addEventListener('click', (event) => {
        this.trackProductClick(event, trackingData, rootDomElement.href)
      })
    } else if (trackingData.type == 'DisplayItem') {
      this.trackDisplayItemImpression(rootDomElement, trackingData)
      rootDomElement.addEventListener('click', (event) => {
        this.trackDisplayItemImpressionClick(event, trackingData, rootDomElement.href)
      })
    }
  }
}

window.Tracker = Tracker


export default Tracker
