const headTag = document.getElementsByTagName('head')[0]
let isAdimoLoading = false
let isAdimoLoaded = false
let loadPromise = null
const libraries = {
  adimoLightbox: 'https://cdn.adimo.co/scripts/lightboxv2.min.js',
  adimoRamsay: 'https://cdn.adimo.co/scripts/ramsay.min.js'
}

const getLibrary = (url) => {
  return new Promise((resolve, reject) => {
    const scriptHTML = document.createElement('script')

    scriptHTML.type = 'text/javascript'
    scriptHTML.async = true
    scriptHTML.src = url

    scriptHTML.onload = function () {
      resolve(url)
    }
    scriptHTML.onerror = function () {
      reject('error')
    }

    headTag.appendChild(scriptHTML)
  })
}

const initMap = () => {
  Adimo.main.init('https://basketads.adimo.co', false)
}

const loadMap = () => {
  return getLibrary(libraries.adimoLightbox)
    .then(() => getLibrary(libraries.adimoRamsay))
}

export default {
  async loadAdimo() {
    if (isAdimoLoading || isAdimoLoaded) {
      return loadPromise
    }

    isAdimoLoading = true

    try {
      loadPromise = loadMap()
      await loadPromise
      initMap()
      isAdimoLoaded = true
    } finally {
      isAdimoLoading = false
    }
  }
}
