export default function initializer(elementToInitialize) {
  // return if no element to init
  if (typeof elementToInitialize === 'undefined') {
    return
  }

  // Ensure that invocation of console.log doesn't break on older browsers
  window.vt = window.vt || {}
  window.console = window.console || {}
  window.console.log = window.console.log || function () { }

  const initializers = elementToInitialize.querySelectorAll('[data-js-initialize]')

  const extractProperty = function (o, s) {
    return o[s]
  }

  // Depends on ECMA 5 - use shim for IE8 and older
  const objectReference = function (obj, str) {
    return str.split('.').reduce(extractProperty, obj)
  }

  let element
  let handler
  let i

  for (i = 0; i < initializers.length; i++) {
    element = initializers[i]
    try {
      const modules = element.getAttribute('data-js-initialize').split(' ')
      for (let j = 0; j < modules.length; j++) {
        console.log(modules[j])
        handler = objectReference(window, modules[j])
        handler.initialize(element)
      }
    } catch (ex) {
      console.log(`Error initializing: ${ex.message}`, ex, element)
    }
  }
}
