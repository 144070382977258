var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideInput),expression:"hideInput"}],staticClass:"c-service-menu__search",class:{
    'is-opened': _vm.isFormVisible,
    'is-closing': _vm.isClosing
  }},[_c('form',{ref:"form",staticClass:"c-service-menu__search-form u-flex u-flex-align-center",class:{
      'is-shown': _vm.isInputVisible
    },attrs:{"action":_vm.model.url},on:{"submit":function($event){$event.preventDefault();return _vm.redirectToSearchPage($event)}}},[(!_vm.model.autocompleteDisabled)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"input",staticClass:"c-service-menu__search-field",attrs:{"type":"search","placeholder":_vm.model.inputPlaceholder},domProps:{"value":(_vm.query)},on:{"click":_vm.openSearchField,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onKeyboardEnter($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onKeyboardDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onKeyboardUp($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.hideInput($event)}],"keypress":_vm.resetKeyboardFlag,"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}),(_vm.isFormVisible)?_c('QuickSearchAutocomplete',{attrs:{"query":_vm.query,"offset":_vm.offset,"loading":_vm.isLoadingSuggestions,"suggestions":_vm.suggestions,"active-index":_vm.activeIndex}}):_vm._e()]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"input",staticClass:"c-service-menu__search-field",attrs:{"type":"search","placeholder":_vm.model.inputPlaceholder},domProps:{"value":(_vm.query)},on:{"click":_vm.openSearchField,"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}})]],2),_c('button',{ref:"searchButton",staticClass:"c-service-menu__button c-service-menu__search-trigger",class:{
      'is-hidden': _vm.isInputVisible
    },attrs:{"title":_vm.model.title},on:{"click":function($event){$event.preventDefault();return _vm.openSearchField($event)}}},[_c('Icon',{attrs:{"icon":_vm.iconSearch,"size":24,"stroke":"black"}})],1),(_vm.isInputVisible)?_c('a',{staticClass:"c-service-menu__search-button c-button--icon u-flex-align-center u-flex-justify-center",attrs:{"href":_vm.model.url,"title":_vm.model.title},on:{"click":function($event){$event.preventDefault();return _vm.redirectToSearchPage($event)}}},[_c('Icon',{attrs:{"icon":_vm.iconSearchBold,"size":16,"fill":"white"}})],1):_vm._e(),_c('CloseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInputVisible),expression:"isInputVisible"}],staticClass:"c-service-menu__search-close",on:{"click":_vm.hideInputOnClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }