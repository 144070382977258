<template>
  <button
    class="c-button-close"
    :class="cssClass"
    @click.prevent="$emit('click')"
  >
    <Icon
      :icon="iconClose"
      :size="size"
      :stroke="iconColor"
      :hover-color="iconHoverColor"
    />
  </button>
</template>

<script>
import iconClose from '@ds/svg/icons/stroke/close.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    iconColor: {
      type: String,
      default: 'black'
    },
    iconHoverColor: {
      type: String,
      default: 'medium-gray'
    },
    size: {
      type: Number,
      default: 24
    },
    buttonSize: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconClose
    }
  },
  computed: {
    cssClass() {
      return [
        this.buttonSize == 'small' ? 'c-button-close--small' : ''
      ]
    }
  }
}
</script>
