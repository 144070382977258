(function ($) {
  $.fn.loadMorePosts = function (el) {
    const scrollTop = function (el) {
      $('html, body').animate({
        scrollTop: el.offset().top
      }, 800)
    }
    $.fn.handleSocialShowMoreButton = function ($showMore) {
      const
        dataIndex = $showMore.attr('data-index')
      let currentIndex
      let href
      let hrefStart

      if (dataIndex) {
        currentIndex = parseInt(dataIndex)
        $showMore.attr('data-index', currentIndex + 1)
        href = $showMore.attr('data-src')
        hrefStart = href.substring(0, href.indexOf('index=') + 6)
        $showMore.attr('data-src', hrefStart + (currentIndex + 1))
      }
    }
    $.fn.handleMasonry = function (masonryContainerEl) {
      masonryContainerEl
        .masonry()
        .masonry('reloadItems')
        .masonry('layout')
    },
    $.fn.makeSocialActionLinksPopup = function () {
      $('.social-grid-item').on('click', '.interact a, a.followl', function (e) {
        e.preventDefault()
        e.stopPropagation()
        const
          loc = $(this).attr('href')
        let windowWidth
        let windowHeight
        let topPos
        let leftPos

        if ($(window).width() > 500) {
          windowWidth = 450
          windowHeight = 550
        } else {
          windowWidth = $(window).width() * 0.9
          windowHeight = $(window).height() * 0.8
        }
        topPos = $(window).height() / 2 - windowHeight / 2
        leftPos = $(window).width() / 2 - windowWidth / 2
        window.open(loc, 'window', `height=${windowHeight}, width=${windowWidth}, top=${topPos}, left=${leftPos}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`)
      })
    }
    $.fn.makeSocialActionLinksPopup()
    $(window).load(() => {
      if ($(el).data('target') === '.instagrams') $.fn.handleMasonry($($(el).data('target')))
    })

    $(el).on('click', function () {
      $(this).addClass('show-loader')
      $.ajax({
        context: this,
        type: 'GET',
        cache: false,
        url: $(this).attr('data-src'),
        success(data) {
          const
            _this = this
          const targetSelector = $(this).attr('data-target')
          const appendTo = $(this).closest('.show-more-parent').find(targetSelector)
          let el
          const doNotFade = $(this).attr('data-no-fade') && $(this).attr('data-no-fade') == 'true'
          const doNotScroll = $(this).attr('data-no-scroll') && $(this).attr('data-no-scroll') == 'true'
          const doReplace = $(this).attr('data-replace') && $(this).attr('data-replace') == 'true'
          if (doReplace) {
            el = appendTo.html(data).fadeIn('slow')
          } else if (doNotFade) {
            el = $(data).appendTo(appendTo)
            if (!doNotScroll) {
              scrollTop(el)
            }
          } else {
            let animating = false
            el = $(data).hide().appendTo(appendTo).fadeIn('slow', function () {
              $(this).css('display', '')
              if (!animating) {
                animating = true
                if (targetSelector === '.instagrams') $.fn.handleMasonry($(appendTo))
                else scrollTop(el)
              }
            })
          }
          $.fn.handleSocialShowMoreButton($(this))
          $(this).removeClass('show-loader')
        }
      })
      return false
    })
  }
}(jQuery))
