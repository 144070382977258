<template>
  <button
    class="c-service-menu__button"
    :title="model.panelLink && model.panelLink.title"
    :class="{
      'is-opened': store.state.isShoppingPanelVisible
    }"
    @click.prevent="openShoppingPanel"
  >
    <Icon
      :icon="iconShoppingCart"
      :size="24"
      stroke="black"
    />
    <template v-if="itemCount > 0">
      <span
        class="c-service-menu__button-badge"
        :class="{
          'is-animating': isAnimated
        }"
      >
        <span>
          {{ itemCount }}
        </span>
      </span>
    </template>
  </button>
</template>

<script>
import iconShoppingCart from '@ds/svg/icons/stroke/shopping-cart.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import purchaseStore from '@/CVI/Purchase/store'
import store from '@/CVI/WebCore/store'
import tracker from '@/CVI/WebCore/core-tracker'
import mountComponent from '@/CVI/WebCore/utils/mount-component-to-body'


export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconShoppingCart,
      purchaseStore,
      store,
      isAnimated: false
    }
  },
  computed: {
    itemCount() {
      return this.purchaseStore.state.cart.length
    }
  },
  watch: {
    itemCount() {
      this.isAnimated = true
      setTimeout(() => {
        this.isAnimated = false
      }, 600)
    }
  },
  mounted() {
    import('@/CVI/WebCore/components/Navigation/ServiceMenu/ShoppingCartPanel.vue').then((module) => {
      mountComponent({
        component: module.default,
        props: {
          model: this.model
        }
      })
    })
  },
  methods: {
    trackShoppingCartIconClick() {
      tracker.trackSimpleEvent({
        event: 'menuNavigationShoppingCartOpen'
      })
    },
    openShoppingPanel() {
      this.store.state.isShoppingPanelVisible = true
      this.trackShoppingCartIconClick()
    }
  }
}
</script>
