(function ($) {
  window.vt = window.vt || {}

  vt.initMasonry = (function () {
    function initialize(element) {
      // do not apply masonry for mobile layout
      if (window.innerWidth < 500) return

      const $container = $(element).find('.content-container')
      $container.masonry({
        itemSelector: '.content-block',
        columnWidth: 320,
        gutter: 0
      })
    }

    return {
      initialize
    }
  }())
}(jQuery))
